@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700&family=Inter:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&family=Ysabeau:ital,wght@0,1..1000;1,1..1000&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

:focus {
  outline: 0;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: block;
}

a:hover {
  text-decoration: none;
}

input, textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

label, button, input[type=submit], input[type=button] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
}

button, input[type=submit] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: 'Rethink Sans';
  src: url('../../assets/fonts/RethinkSans-Regular.woff2') format('woff2'),
  url('../../assets/fonts/RethinkSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */
/* @font-face {
  font-family: 'Ysabeau Office';
  src: url('../../assets/fonts/YsabeauOffice-Bold.woff2') format('woff2'),
  url('../../assets/fonts/YsabeauOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */
/* @font-face {
  font-family: 'Ysabeau';
  src: url('../../assets/fonts/Ysabeau-BoldItalic.woff2') format('woff2'),
  url('../../assets/fonts/Ysabeau-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
} */
@font-face {
  font-family: "Archivo";
  src: url("../../assets/fonts/Archivo-SemiBold.woff2") format("woff2"), url("../../assets/fonts/Archivo-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
:root {
  --cl-black: #050505;
  --cl-black-rgb: 0 0 0;
  --cl-black-80: rgba(5, 5, 5, 0.8);
  --cl-black-2: #121212;
  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;
  --gradient: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
  --cl-white: #F0EEEE;
  --cl-white-rgb: 255 255 255;
  --cl-white-80: rgba(240, 238, 238, 0.8);
  --cl-orange: #EC731F;
  --cl-purple: #9378C8;
  --cl-green: #A6D1C7;
  --cl-aqua: #B0D8CE;
  --cl-aqua-80: rgba(176, 216, 206, 0.8);
  --cl-grey: #D2D2D2;
  --cl-blue: #4bafe4;
  --cl-light-grey: #F6F6F6;
  --cl-slate: #20232E;
  --font-main: "Archivo", sans-serif;
  --font-second: "Ysabeau", sans-serif;
  --font-third: "Ysabeau Office", sans-serif;
  --font-fourth: "Rethink Sans", sans-serif;
  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --header-height: clamp(88px, calc(88px + 32.00 * ((100vw - 768px) / 672)), 120px);
  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */
/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
/* ../../assets/ */
@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?ww8db1");
  src: url("../../assets/fonts/icomoon.eot?ww8db1#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon.ttf?ww8db1") format("truetype"), url("../../assets/fonts/icomoon.woff?ww8db1") format("woff"), url("../../assets/fonts/icomoon.svg?ww8db1#icomoon") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-chevron:before {
  content: "\e900";
}

.icon-arrow-down:before {
  content: "\e901";
}

.icon-arrow-left:before {
  content: "\e902";
}

.icon-arrow-next:before {
  content: "\e903";
}

.icon-arrow-prev:before {
  content: "\e904";
}

.icon-arrow-right:before {
  content: "\e905";
}

.icon-arrow-up:before {
  content: "\e906";
}

.icon-check:before {
  content: "\e907";
}

.icon-close:before {
  content: "\e908";
}

.icon-developer:before {
  content: "\e909";
}

.icon-instagram:before {
  content: "\e90a";
}

.icon-linkedin:before {
  content: "\e90b";
}

.icon-location:before {
  content: "\e90c";
}

.icon-mail:before {
  content: "\e90d";
}

.icon-mail-second:before {
  content: "\e90e";
}

.icon-minus:before {
  content: "\e90f";
}

.icon-phone-second:before {
  content: "\e910";
}

.icon-plus:before {
  content: "\e911";
}

.icon-price:before {
  content: "\e912";
}

.icon-search:before {
  content: "\e913";
}

.icon-twitter:before {
  content: "\e914";
}

a {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.heading-gradient span {
  font-family: var(--font-second);
  line-height: 112%;
  font-style: italic;
  background: linear-gradient(76.44deg, rgba(145, 243, 218, 0.96) 41.08%, #F4EAE5 80.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  letter-spacing: clamp(0.68px, 0.68px + 0.22 * (100vw - 768px) / 672, 0.9px);
}

.heading-1 {
  font-weight: 600;
  font-size: clamp(46px, 46px + 34 * (100vw - 768px) / 672, 80px);
  line-height: 112%;
  letter-spacing: clamp(0.69px, 0.69px + 0.51 * (100vw - 768px) / 672, 1.2px);
  color: var(--cl-white);
}

.heading-2 {
  font-weight: 600;
  font-size: clamp(42px, 42px + 14 * (100vw - 768px) / 672, 56px);
  letter-spacing: clamp(0.62px, 0.62px + 0.22 * (100vw - 768px) / 672, 0.84px);
  line-height: 112%;
}

.heading-3 {
  font-weight: 600;
  font-size: clamp(28px, 28px + 8 * (100vw - 768px) / 672, 36px);
  line-height: 112%;
}

.heading-4 {
  font-weight: 600;
  font-size: clamp(60px, 60px + 20 * (100vw - 768px) / 672, 80px);
  line-height: 112%;
}

.content-element {
  font-size: clamp(14px, 14px + 8 * (100vw - 768px) / 672, 22px);
  font-weight: 400;
  line-height: 1.4;
}

.content-element ol li, .content-element ul li {
  list-style-position: inside;
}

.content-element ol {
  list-style: decimal;
}

.content-element ul {
  list-style: disc;
}

.content-element ul, .content-element ol, .content-element p {
  /*a {
    font: inherit;
    color: var(--cl-orange);
    transition: color .3s ease;
    text-decoration: underline;

    &:hover {
      color: var(--cl-slate);
    }
  }*/
}

/*Not Design*/
.content-element ul:not(:last-child), .content-element ol:not(:last-child), .content-element p:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.content-element.big-size p, .content-element.big-size li {
  font-family: var(--font-third);
  font-size: clamp(18px, 18px + 8 * (100vw - 768px) / 672, 26px);
  line-height: 1.4;
  font-weight: 600;
  font-style: normal;
}

.content-element.big-size p:not(:last-child), .content-element.big-size li:not(:last-child) {
  margin-bottom: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
}

.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-green) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);
  color: var(--cl-white);
  font-family: var(--font-fourth);
  overflow-x: hidden;
  min-height: calc(100 * var(--vh));
  display: flex;
}

body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: var(--cl-black);
  border-radius: 0;
}

body::-webkit-scrollbar-thumb {
  background: var(--cl-green);
  border-radius: 0;
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1224px;
  width: 85.6944444444%;
}

@media not all and (min-width: 1024px) {
  .cont {
    width: 89.33%;
  }
}
section {
  position: relative;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.logo {
  transition: 0.3s ease;
}

.logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.logo:hover {
  transform: scale(1.06);
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;
}

#bold-credits::before, #bold-credits::after {
  display: none;
}

#bold-credits:hover {
  transform: scale(1.1);
}

@media not all and (min-width: 640px) {
  #bold-credits {
    width: 159px;
  }
}
#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

#site.cookie-terms-page .main {
  flex-grow: 1;
  padding: 0;
}

.cookie-terms-section {
  padding: clamp(140px, 140px + 110 * (100vw - 768px) / 672, 250px) 0 clamp(50px, 50px + 100 * (100vw - 768px) / 672, 150px);
}

.cookie-terms-section p {
  padding: 0;
  margin-bottom: 10px;
}

.cookie-terms-section ul, .cookie-terms-section ol {
  padding-left: 20px;
}

.cookie-terms-section ul li p, .cookie-terms-section ol li p {
  margin-bottom: 0;
}

.cookie-terms-section ul {
  list-style: circle;
}

.cookie-terms-section ol {
  list-style: decimal;
}

.cookie-terms-section a {
  display: inline;
  text-decoration: underline;
  transition: 0.2s;
}

.cookie-terms-section a:hover {
  opacity: 0.7;
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-page-404 h1, .single-page-404 p {
  margin-bottom: 15px;
  padding: 0;
}

.single-page-404 a {
  display: inline-block;
  margin-top: 20px;
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 6px;
  border: 2px solid var(--cl-slate);
  overflow: hidden;
  background: var(--cl-slate);
  color: var(--cl-orange);
  transition: color 0.3s ease;
  width: 32px;
  height: 32px;
  position: relative;
}

.social-link i {
  position: relative;
  z-index: 5;
}

.social-link:before {
  position: absolute;
  inset: 0;
  background: var(--cl-orange);
  content: "";
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

/*i.icon-youtube {
    margin-bottom: -6px;
  }*/
.social-link:hover {
  color: var(--cl-grey);
}

.social-link:hover:before {
  transform: translateY(0);
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.holding {
  overflow: hidden;
  position: relative;
  z-index: 5;
}

.holding__bg {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.holding__bg::before {
  content: "";
  display: block;
  padding-bottom: max(min(50.3496503497%, 9999px), 0px);
}

.holding__bg img, .holding__bg svg, .holding__bg video, .holding__bg iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.holding__bg[data-parallax-el] {
  overflow: visible;
  -webkit-mask-image: none;
  mask-image: none;
}

.holding__bg[data-parallax-el] img, .holding__bg[data-parallax-el] svg {
  position: absolute !important;
}

.holding__bg {
  width: 150%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-60%) translateX(-12%);
  max-width: 2300px;
}

@media not all and (min-width: 640px) {
  .holding__bg {
    display: none;
  }
}
.holding__bg-container {
  position: relative;
  overflow: hidden;
}

.holding__bg-container::before {
  content: "";
  display: block;
  padding-bottom: max(min(62.3279098874%, 9999px), 0px);
}

.holding__bg-container {
  width: 62.62%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) translateX(30%) !important;
  overflow: visible !important;
  z-index: -1;
  min-width: 420px;
}

@media not all and (min-width: 640px) {
  .holding__bg-container {
    opacity: 0.8;
  }
}
.holding__bg-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
}

.holding__bg-circle .circle {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.holding__bg-circle .circle::before {
  content: "";
  display: block;
  padding-bottom: max(min(100%, 9999px), 0px);
}

.holding__bg-circle .circle img, .holding__bg-circle .circle svg, .holding__bg-circle .circle video, .holding__bg-circle .circle iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.holding__bg-circle .circle[data-parallax-el] {
  overflow: visible;
  -webkit-mask-image: none;
  mask-image: none;
}

.holding__bg-circle .circle[data-parallax-el] img, .holding__bg-circle .circle[data-parallax-el] svg {
  position: absolute !important;
}

.holding__bg-circle .circle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: blur(10px);
}

.holding__bg-circle:nth-child(1) {
  z-index: 2;
}

@media (min-width: 640px) {
  .holding__bg-circle:nth-child(1) {
    top: -35%;
  }
}
.holding__bg-circle:nth-child(2) {
  left: auto;
  right: 0;
}

@media (min-width: 640px) {
  .holding__bg-circle:nth-child(2) {
    top: 10%;
  }
}
.holding__bg-page {
  position: absolute;
  inset: 0;
}

.holding__bg-page img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.holding__wrap {
  padding-top: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  z-index: 10;
  min-height: 100vh;
}

.holding__logo {
  position: relative;
  overflow: hidden;
  /* fix for hover scale on image on Safari 15.1 */
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.holding__logo::before {
  content: "";
  display: block;
  padding-bottom: max(min(108.0076226034%, 9999px), 0px);
}

.holding__logo img, .holding__logo svg, .holding__logo video, .holding__logo iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.holding__logo[data-parallax-el] {
  overflow: visible;
  -webkit-mask-image: none;
  mask-image: none;
}

.holding__logo[data-parallax-el] img, .holding__logo[data-parallax-el] svg {
  position: absolute !important;
}

.holding__logo {
  width: 100%;
  max-width: clamp(130px, 130px + 90 * (100vw - 768px) / 672, 220px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: clamp(-34px, 0px + -34 * (100vw - 768px) / 672, 0px);
}

.holding__logo img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: left;
  object-position: left;
}

.holding__logo:not(:last-child) {
  margin-bottom: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px);
}

.holding__text {
  width: 100%;
  max-width: clamp(450px, 450px + 170 * (100vw - 768px) / 672, 620px);
}

.holding__text:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.holding__text .underline {
  text-underline-offset: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
}

.holding__socials:not(:last-child) {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
}

.holding__links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.holding__link {
  display: inline-flex;
  font-weight: 500;
  font-size: clamp(18px, 18px + 8 * (100vw - 768px) / 672, 26px);
  line-height: normal;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 5;
  transition: color 0.31s ease;
  color: var(--cl-green);
}

.holding__link:before {
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  height: 2px;
  background: var(--cl-green);
  transition: background-color 0.4s ease, height 0.2s ease, opacity 0.3s ease;
  position: absolute;
  z-index: -1;
}

.holding__link:hover:before {
  transition: background-color 0.3s ease, height 0.3s ease;
  height: 50%;
  background-color: var(--cl-white);
  opacity: 0.3;
}

.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  -webkit-mask-image: radial-gradient(white, black);
  mask-image: radial-gradient(white, black);
}

.image-aspect-ratio::before {
  content: "";
  display: block;
  padding-bottom: min(100% * var(--arh) / var(--arw));
}

.image-aspect-ratio img, .image-aspect-ratio video {
  width: 100%;
  height: 100%;
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  left: 0;
}

.border-gradient-block {
  position: relative;
  z-index: 1;
}

.border-gradient-block:after {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  background: var(--gradient);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  border-radius: inherit;
}

.for-candidates-container {
  position: relative;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
}

.for-candidates-container-bg {
  position: absolute;
  inset: 0;
  z-index: 0;
}

.for-candidates-container-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.for-candidates-container-decor {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  max-width: clamp(164px, 164px + 246 * (100vw - 320px) / 672, 410px);
}

.for-candidates-container-decor img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  opacity: 0.5;
}

/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: var(--header-height);
  border-bottom: 1px solid transparent;
}

.header.absolute {
  position: absolute;
}

.header.active {
  background-color: var(--cl-black);
  border-color: #b0d8ce;
}

.header.scrolled {
  background-color: var(--cl-black);
  border-color: #b0d8ce;
}

/* .cont {
    padding-right: ac(23px, 24px);
    padding-left: ac(35px, 24px);
    z-index: 3;
  } */
.header.fixed {
  position: fixed;
}

/* &.scrolled {
    &:not(.open-menu) {
      .logo {
        max-width: 190px;
        @mixin max-xs {
          max-width: 165px;
        }
      }
    }
  } */
.header .logo {
  height: auto;
  width: 100%;
  max-width: 156px;
  transition: transform 0.25s ease, width 0.25s ease;
  z-index: 100;
}

.header .logo:hover {
  transform: scale(1.04);
}

.header .logo img {
  width: 100%;
  height: 100%;
}

@media (max-width: 640px) {
  .header .logo {
    max-width: 120px;
  }
}
/* &.scrolled {
    .logo {
      width: 90%;
    }
  } */
@media (min-width: 1200px) {
  .header-bottom:after {
    display: none;
  }
}
/* base descktop navbar */
.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* padding: ac(13px, 10px) ac(50px, 42px);
    @media (max-width: 768px) {
      padding: 6px 12px 6px 24px;
    } */
.header .navbar.active {
  margin-right: 11px;
}

@media (pointer: coarse) {
  .header .navbar.active {
    margin-right: 0;
  }
}
/* &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  } */
.header .burger-wrap {
  display: none;
}

/* list, menu */
.header .navbar-nav {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.header .navbar-nav .menu-list {
  display: flex;
  flex-grow: 1;
}

.header .navbar-nav__decor {
  display: none;
}

/* hz */
.header .menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.header .menu-bg {
  position: absolute;
  display: none;
  inset: 0;
  width: 100%;
  height: auto;
  opacity: 0.3;
}

.header .menu-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (min-width: 1200px) {
  .header .menu {
    width: auto;
    flex: 1;
    /* padding-left: ac(60px, 20px); */
    align-items: center;
  }
  .header .menu .simplebar-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .header .menu .simplebar-content .menu-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  }
  .header .menu .simplebar-content:after {
    display: none;
  }
}
/* min-width: 1200px */
.header .menu:not([data-simplebar=init]) {
  display: none;
}

/* &::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    backdrop-filter: blur(12px);
    background: rgba(5, 61, 128, 0.5);
  } */
@media (max-width: 1200px) {
  .header .navbar-nav.active .menu-bg {
    display: flex;
  }
}
.header .menu-item {
  position: relative;
}

@media (min-width: 1200px) {
  .header .menu-item:after {
    display: none;
  }
}
.header .menu-item__icon {
  display: none;
}

.header .menu-item.dropdown > .menu-link {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.header .menu-item.dropdown > .menu-link::after {
  content: "\e999";
  font-family: "icomoon";
  font-size: clamp(8px, 8px + 2 * (100vw - 768px) / 672, 10px);
  padding-left: 10px;
}

/* mobile menu addt content */
.header .menu-additional-content {
  margin-left: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  /* display: none; */
}

.header .menu-additional-content .btn {
  height: 48px;
}

@media not all and (min-width: 1200px) {
  .header .menu-additional-content {
    /*transform: translateY(-8px);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: auto;
    margin-left: 0;
  }
  .header .menu-additional-content .btn {
    width: 100%;
  }
}
/* nav-list */
.header .menu-link {
  position: relative;
  color: var(--cl-white);
  transition: all 0.3s ease;
  letter-spacing: 0.24px;
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  line-height: 1.4;
  color: #fff;
}

.header .menu-link i {
  display: none;
}

.header .menu-link::before {
  content: "";
  position: absolute;
  bottom: -6.5px;
  right: 0;
  width: 0;
  height: 1px;
  background: var(--cl-white);
  transition: all 0.3s ease;
}

.header .menu-link.active::before {
  width: 100%;
  left: 0;
}

.header .menu-link:hover {
  color: var(--cl-white-80);
}

.header .menu-link:hover::before {
  width: 100%;
  left: 0;
  background: var(--cl-white-80);
}

.header .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  padding: 10px 10px 10px 15px;
  border-radius: 10px;
  background: var(--cl-grey);
  box-sizing: content-box;
  display: none;
}

.header .dropdown-menu li .menu-link {
  padding: 5px 0;
  text-transform: capitalize;
}

.header-bottom {
  display: flex;
}

.header-slider {
  max-width: 300px;
  overflow: visible;
  margin-bottom: 24px;
  display: none;
}

.header-card {
  border-radius: 16px;
  padding: 20px 24px;
  max-width: 300px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
  background: #050505;
  /* border: 1px solid rgba(174, 214, 204, 0.96); */
  --gradient: linear-gradient(
    46deg,
    rgba(145, 243, 218, 0.96) 0%,
    #f4eae5 100%
  );
}

.header-card__text {
  min-height: 116px;
  margin-bottom: 47px;
  font-weight: 800;
}

.header-card__title {
  font-size: 24px;
  margin-bottom: 12px;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-card__descr {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
  color: #fff;
}

.header-card__info-item {
  width: 100%;
  display: flex;
  align-items: center;
  --gradient: linear-gradient(
    46deg,
    rgba(145, 243, 218, 0.96) 0%,
    #f4eae5 100%
  );
}

.header-card__info-item:not(:last-child) {
  margin-bottom: 16px;
}

.header-card__info-item i {
  font-size: 22px;
  margin-right: 10px;
}

.header-card__info-item span {
  color: #fff;
  text-decoration: underline;
}

/* end of Common desktop header */
/* ----------------------- Common desktop header Mobile HEADER ----------------------- */
/* min-1200? */
@media not all and (min-width: 1200px) {
  .header-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(176, 216, 206, 0.24), 0 0 16px 0 rgba(176, 216, 206, 0.08);
    background: #050505;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid rgba(176, 216, 206, 0.24);
    width: 100%;
    max-width: 327px;
    --gradient: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
  }
  @media (max-width: 640px) {
    .header-bottom {
      max-width: 100%;
    }
  }
  /* mobile menu */
  .header:not(.header-mobile) .burger, .header:not(.header-mobile) .menu-toggle {
    display: flex;
    margin-right: -2px;
  }
  .header:not(.header-mobile) .burger-wrap {
    display: flex;
  }
  .header:not(.header-mobile) .navbar-nav {
    position: fixed;
    right: 0;
    top: var(--header-height);
    width: 45%;
    /* height: 100%; */
    max-height: calc(var(--vh) * 100);
    height: calc(var(--vh) * 100);
    min-width: 320px;
    padding-top: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
    z-index: -1;
    background: transparent;
    padding-right: 5px;
    padding-bottom: 120px;
    transition: opacity 0.25s ease-out, background-color 0.4s ease 0.1s, border-radius 0.3s ease, -webkit-clip-path 0.5s ease;
    transition: opacity 0.25s ease-out, clip-path 0.5s ease, background-color 0.4s ease 0.1s, border-radius 0.3s ease;
    transition: opacity 0.25s ease-out, clip-path 0.5s ease, background-color 0.4s ease 0.1s, border-radius 0.3s ease, -webkit-clip-path 0.5s ease;
    -webkit-clip-path: circle(25px at calc(100% - 45px) 45px);
    clip-path: circle(25px at calc(100% - 45px) 45px);
    opacity: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    pointer-events: none;
    /* padding-bottom: 100px; */
    /* full mobile menu 640 */
  }
  @media (max-width: 992px) {
    .header:not(.header-mobile) .navbar-nav {
      width: 45%;
    }
  }
  @media (max-width: 768px) {
    .header:not(.header-mobile) .navbar-nav {
      width: 55%;
    }
  }
  /* @mixin media 970 {
    width: 67%;
  } */
  .header:not(.header-mobile) .navbar-nav__image {
    position: absolute;
    inset: 0;
  }
  .header:not(.header-mobile) .navbar-nav__image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  /* mobile menu active */
  .header:not(.header-mobile) .navbar-nav.active {
    pointer-events: auto;
    -webkit-clip-path: circle(75%);
    clip-path: circle(75%);
    opacity: 1;
    background-color: var(--cl-black);
  }
  .header:not(.header-mobile) .navbar-nav.active .header-slider {
    display: block;
  }
  /* animation */
  .header:not(.header-mobile) .navbar-nav.nav-slide-left {
    right: auto;
    left: 0;
    transform: translateX(-100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-left.active {
    transform: translateX(0);
  }
  /* @media (min-width: 650px) {
    width: 51% !important;
    .menu-list {
      padding-left: ac(60px, 0px, 650, 1024);
    }
  } */
  .header:not(.header-mobile) .navbar-nav.nav-slide-right {
    right: 0;
    left: auto;
    transform: translateX(100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-right.active {
    transform: translateX(0);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-down {
    right: auto;
    left: 0;
    transform: translateY(-100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-down.active {
    transform: translateY(0);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-up {
    right: auto;
    left: 0;
    transform: translateY(100%);
  }
  .header:not(.header-mobile) .navbar-nav.nav-slide-up.active {
    transform: translateY(0);
  }
  /* list, additional content */
  .header:not(.header-mobile) .navbar-nav .menu {
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 40px;
    padding-right: 15px;
    align-items: flex-start;
    align-self: center;
    position: relative;
    z-index: 2;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-wrapper {
    flex: 1;
  }
  .header:not(.header-mobile) .navbar-nav .menu .menu-additional-content {
    /* padding-bottom: 50px; */
    margin-top: 28px;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    position: relative;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-content .menu-list:after {
    position: absolute;
    display: none;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 173px;
    height: 1px;
    background-color: var(--cl-white);
    content: "";
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical {
    width: clamp(4px, 4px + 2 * (100vw - 768px) / 672, 6px);
    background: var(--cl-grey);
    border-radius: 4px;
  }
  /*transform: translateX(ac(6px, 4px));*/
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar {
    background: var(--cl-navy);
    border-radius: 4px;
  }
  .header:not(.header-mobile) .navbar-nav .menu .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    content: none;
  }
  /* mobile list links style */
  .header:not(.header-mobile) .navbar-nav .menu-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 0;
    width: auto;
    font-weight: 800;
    font-size: clamp(15px, 15px + 2 * (100vw - 640px) / 800, 17px);
    line-height: 1.4;
    background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link i {
    display: block;
    color: #fff;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link:before {
    height: 1px;
    display: none;
  }
  .header:not(.header-mobile) .navbar-nav .menu-link:hover {
    opacity: 0.8;
  }
  /* mobile list items style */
  .header:not(.header-mobile) .navbar-nav .menu-item {
    width: 100%;
    padding: 9px 16px;
    margin-bottom: 12px;
    border: 1px solid rgba(174, 214, 204, 0.96);
    border-radius: 100px;
  }
  .header:not(.header-mobile) .navbar-nav .menu-item:last-of-type {
    margin-bottom: 0;
    /* @media screen and (max-height: 700px) {
      margin-bottom: 32px;
    } */
  }
  @media not all and (min-width: 640px) {
    .header:not(.header-mobile) .navbar-nav {
      width: 100%;
      height: calc(var(--vh) * 100);
      padding-bottom: 100px;
      border-bottom-left-radius: 0;
    }
    .header:not(.header-mobile) .navbar-nav .menu {
      padding-left: 20px;
    }
    .header:not(.header-mobile) .navbar-nav .header-bottom {
      max-width: 100%;
    }
    /* padding-top: 32px; */
    .header:not(.header-mobile) .navbar-nav .menu-list::after {
      display: none;
    }
    .header:not(.header-mobile) .navbar-nav .menu-item {
      justify-content: center;
    }
    .header:not(.header-mobile) .navbar-nav .menu-additional-content {
      margin-top: auto;
      justify-content: flex-end;
    }
    /* padding-bottom: 50px; */
  }
  .header:not(.header-mobile) .dropdown-menu {
    position: relative;
    top: 0;
  }
}
/* ----------------------- HEADER COMPONENTS ----------------------- */
/* burger */
.burger-wrap {
  display: flex;
  align-items: center;
}

.burger-text {
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.24px;
  margin-right: 16px;
  font-family: var(--font-fourth);
}

.burger, .menu-toggle {
  position: relative;
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
}

/* base burger */
.burger .burger-icon, .menu-toggle .burger-icon {
  cursor: pointer;
  transition: transform 400ms, background-color 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  /* width: 56px !important;
  height: 56px !important; */
  position: relative;
  z-index: 2;
}

.burger .line, .menu-toggle .line {
  fill: none;
  transition: stroke 0.3s ease, stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: var(--cl-black);
  stroke-width: 3;
  stroke-linecap: round;
}

.burger .top, .menu-toggle .top {
  stroke-dasharray: 40 121;
}

.burger .bottom, .menu-toggle .bottom {
  stroke-dasharray: 40 121;
}

/* active burger */
.burger.active, .menu-toggle.active {
  background-color: transparent;
}

.burger.active:before, .menu-toggle.active:before {
  opacity: 1;
}

.burger.active .line, .menu-toggle.active .line {
  /* stroke: var(--cl-blue-light); */
}

.burger.active .burger-icon, .menu-toggle.active .burger-icon {
  transform: rotate(45deg);
}

.burger.active .top, .menu-toggle.active .top {
  stroke-dashoffset: -88px;
}

.burger.active .bottom, .menu-toggle.active .bottom {
  stroke-dashoffset: -88px;
}

/* base burger + hover */
.burger:hover .line, .menu-toggle:hover .line {
  stroke: rgba(0, 0, 0, 0.8);
}

/* active burger + hover */
.burger.active:hover .line, .menu-toggle.active:hover .line {
  stroke: rgba(0, 0, 0, 0.8);
}

/* open menu bg */
.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.header-close-wrapper.active {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  cursor: pointer;
}

/* end of HEADER COMPONENTS */
.footer {
  background-color: #121212;
  padding-top: clamp(24px, 24px + 56 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(24px, 24px + 32 * (100vw - 768px) / 672, 56px);
}

.footer-wrap {
  display: flex;
  gap: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
  flex-wrap: wrap;
}

/* w100% */
.footer-main {
  padding: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  border-radius: 16px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid #91F3DA;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 900px) {
  .footer-main {
    flex-direction: column;
  }
}
@media (max-width: 574px) {
  .footer-main {
    padding: clamp(18px, 18px + 22 * (100vw - 768px) / 672, 40px);
  }
}
.footer-main__wrap {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .footer-main__wrap {
    flex-wrap: wrap;
    gap: 32px;
  }
}
.footer-logo {
  max-width: clamp(130px, 130px + 94 * (100vw - 768px) / 672, 224px);
  padding-right: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  border-right: 1px solid var(--cl-aqua);
  box-sizing: content-box;
}

@media (max-width: 900px) {
  .footer-logo {
    border-right: 0;
    border-bottom: 1px solid var(--cl-aqua);
    padding: 0;
    padding-bottom: 24px;
    margin-bottom: 16px;
    width: 100%;
    max-width: none;
  }
}
.footer-logo:hover img {
  transform: scale(1.03);
}

.footer-logo img {
  width: 100%;
  height: 100%;
  transition: 0.3s all;
}

@media (max-width: 900px) {
  .footer-logo img {
    max-width: clamp(120px, 120px + 104 * (100vw - 768px) / 672, 224px);
  }
}
.footer-text {
  max-width: clamp(400px, 400px + 52 * (100vw - 768px) / 672, 452px);
  margin-left: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
}

@media (max-width: 900px) {
  .footer-text {
    margin-left: 0;
    max-width: clamp(350px, 350px + 50 * (100vw - 720px) / 180, 400px);
  }
}
@media (max-width: 720px) {
  .footer-text {
    max-width: none;
    width: 100%;
  }
}
.footer-text__title {
  font-weight: 600;
  font-size: clamp(26px, 26px + 2 * (100vw - 768px) / 672, 28px);
  line-height: 1.32;
  letter-spacing: 0.28px;
  color: #fff;
  margin-bottom: 8px;
}

.footer-text__descr {
  font-weight: 400;
  font-size: clamp(17px, 17px + 1 * (100vw - 768px) / 672, 18px);
  line-height: 1.56;
  color: var(--cl-aqua);
  margin-bottom: 24px;
}

@media (max-width: 640px) {
  .footer-text__descr {
    line-height: 1.44;
  }
}
.footer-text__btn {
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 576px) {
  .footer-text__btn {
    width: 100%;
  }
}
.footer-links {
  margin-left: clamp(24px, 24px + 56 * (100vw - 768px) / 672, 80px);
  max-width: clamp(240px, 240px + 52 * (100vw - 768px) / 672, 292px);
}

@media (max-width: 900px) {
  .footer-links {
    margin-left: 0;
  }
}
@media (max-width: 720px) {
  .footer-links {
    max-width: none;
    width: 100%;
  }
}
.footer-links__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.32;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
}

.footer-links__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: clamp(9px, 9px + 3 * (100vw - 768px) / 672, 12px) clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  width: 100%;
  max-width: 291px;
  font-weight: 800;
  font-size: clamp(15px, 15px + 1 * (100vw - 768px) / 672, 16px);
  border: 1px solid rgba(174, 214, 204, 0.96);
  line-height: 1.4;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.3s all;
}

.footer-links__item:not(:last-child) {
  margin-bottom: 12px;
}

.footer-links__item i {
  color: rgba(174, 214, 204, 0.96);
  font-size: clamp(18px, 18px + 4 * (100vw - 768px) / 672, 22px);
  transition: 0.3s all;
}

.footer-links__item:hover {
  opacity: 0.85;
}

.footer-links__item:hover i {
  /* transform: translateX(5px); */
}

.footer-links__socials {
  margin-top: clamp(28px, 28px + 12 * (100vw - 768px) / 672, 40px);
  display: flex;
  align-items: center;
}

.footer-links__socials span {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.32;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
}

.footer-links__social {
  --size: clamp(40px, calc(40px + 4.00 * ((100vw - 768px) / 672)), 44px);
  width: var(--size);
  height: var(--size);
  border-radius: 100%;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links__social:not(:last-child) {
  margin-right: 12px;
}

.footer-links__social i {
  color: var(--cl-black);
  font-size: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  transition: 0.3s all;
}

.footer-links__social:hover i {
  transform: scale(1.1);
}

.footer-location {
  padding: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px) clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  min-height: clamp(180px, 180px + 45 * (100vw - 768px) / 672, 225px);
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid #91F3DA;
  border-radius: 16px;
  width: 49%;
}

@media (max-width: 900px) {
  .footer-location {
    width: 100%;
  }
}
.footer-location__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.32;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: clamp(12px, 12px + 12 * (100vw - 768px) / 672, 24px);
}

.footer-location__address {
  font-weight: 400;
  font-size: clamp(17px, 17px + 1 * (100vw - 768px) / 672, 18px);
  line-height: 1.48;
  color: #fff;
  margin-bottom: 24px;
}

.footer-location__links {
  display: flex;
  gap: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  flex-wrap: wrap;
}

.footer-location__link {
  display: flex;
  align-items: center;
  transition: 0.3s all;
}

.footer-location__link i {
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  margin-right: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
  width: 24px;
}

.footer-location__link i.icon-mail {
  font-size: clamp(24px, 24px + 6 * (100vw - 768px) / 672, 30px);
}

.footer-location__link span {
  font-weight: 500;
  font-size: clamp(17px, 17px + 1 * (100vw - 768px) / 672, 18px);
  line-height: 1.48;
  text-decoration: underline;
  color: #fff;
  transition: 0.3s all;
}

.footer-location__link:hover {
  opacity: 0.85;
}

.footer-location__link:hover span {
  text-decoration-color: transparent;
}

.footer-bottom {
  background-color: var(--cl-black);
  padding: 24px 0;
}

.footer-bottom__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 16px;
}

.footer-bottom__copy {
  font-weight: 500;
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  line-height: 1.48;
  color: #999;
}

.footer-bottom__links {
  display: flex;
  gap: clamp(8px, 8px + 8 * (100vw - 768px) / 672, 16px);
  flex-wrap: wrap;
}

@media (max-width: 470px) {
  .footer-bottom__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 290px;
  }
}
.footer-bottom__link {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.48;
  text-decoration: underline;
  color: #b0d8ce;
  transition: 0.3s all;
}

.footer-bottom__link:not(:last-child) {
  border-right: 1px solid rgba(176, 216, 206, 0.5);
  padding-right: clamp(8px, 8px + 8 * (100vw - 768px) / 672, 16px);
}

@media (max-width: 576px) {
  .footer-bottom__link:not(:last-child) {
    border: none;
    padding: 0;
  }
}
.footer-bottom__link:hover {
  text-decoration-color: transparent;
}

:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;
  --pp-black: #000;
  --pp-black-rgb: 0 0 0;
  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;
  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;
  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;
  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;
  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;
  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;
}

.popup.active {
  display: block;
}

.popup__close-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: -1;
  transition: all 0.5s ease;
  animation-duration: 0.3s;
  animation-name: opacityPopupBg;
}

.popup__block {
  --pp-default-translate-x-from: -50%;
  --pp-default-translate-x-to: -50%;
  --pp-default-translate-y-from: -50%;
  --pp-default-translate-y-to: -50%;
  --pp-opacity-from: 0;
  --pp-opacity-to: 1;
  background: var(--pp-white);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: clamp(600px, 600px + 250 * (100vw - 768px) / 672, 850px);
  padding: clamp(30px, 30px + 10 * (100vw - 768px) / 672, 40px) clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
  transition: all 300ms ease-out;
  animation-duration: 0.6s;
}

.popup__block.without-opacity {
  --pp-opacity-from: 1;
}

.popup__block.pos-l, .popup__block.pos-r {
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.pos-t, .popup__block.pos-b {
  --pp-default-translate-y-from: 0;
  --pp-default-translate-y-to: 0;
}

.popup__block.pos-t {
  top: 0;
  bottom: auto;
  bottom: initial;
}

.popup__block.pos-r {
  right: 0;
  left: auto;
  left: initial;
}

.popup__block.pos-b {
  top: auto;
  top: initial;
  bottom: 0;
}

.popup__block.pos-l {
  right: auto;
  right: initial;
  left: 0;
}

.popup__block.full-height {
  max-height: 100vh;
  height: 100%;
  right: 0;
  left: auto;
  left: initial;
  --pp-default-translate-x-from: 0;
  --pp-default-translate-x-to: 0;
}

.popup__block.full-height .popup__content {
  max-height: calc(var(--vh, 1vh) * 72);
}

.popup__block.slide-l {
  --pp-default-translate-x-from: 100%;
}

.popup__block.slide-r {
  --pp-default-translate-x-from: -100%;
}

.popup__block.slide-t {
  --pp-default-translate-y-from: -100%;
}

.popup__block.slide-b {
  --pp-default-translate-y-from: 100%;
}

.popup__block.slide-t, .popup__block.slide-r, .popup__block.slide-b, .popup__block.slide-l {
  animation-name: popupSlide;
}

.popup__title {
  color: var(--pp-dark-blue);
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  line-height: 1.2;
  font-family: var(--pp-font-main);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.popup__btn-close {
  position: absolute;
  right: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  top: clamp(15px, 15px + 10 * (100vw - 768px) / 672, 25px);
  transition: all 300ms ease-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup__btn-close .close-clip {
  transition: inherit;
  width: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  height: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  background: var(--pp-dark-blue);
  -webkit-clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
  clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
}

.popup__btn-close i {
  transition: inherit;
  font-size: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  color: var(--pp-dark-blue);
}

.popup__btn-close:hover .close-clip {
  background: var(--pp-blue);
  transform: scale(1.05);
}

.popup__btn-close:hover i {
  color: var(--pp-blue);
  transform: scale(1.05);
}

.popup__content {
  max-height: calc(var(--vh, 1vh) * 66);
}

.popup__content .simplebar-track.simplebar-vertical {
  width: 4px;
  background: var(--pp-light-gray);
  border-radius: 4px;
  transform: translateX(10px);
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: var(--pp-dark-blue);
  border-radius: 4px;
}

.popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.popup__content .popup-form__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@media not all and (min-width: 640px) {
  .popup__content .popup-form__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.popup__content .popup-form__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  flex-wrap: wrap;
}

.popup__content .popup-form__bottom .popup-btn {
  margin-left: auto;
}

.popup__content .popup-btn {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  font-weight: 700;
  font-family: var(--pp-font-main);
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--pp-dark-blue);
  color: var(--pp-white);
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 12px 18px;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  position: relative;
  overflow: hidden;
}

.popup__content .popup-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: skew(45deg);
  pointer-events: none;
}

.popup__content .popup-btn:hover::after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.7) 100%);
  animation: gradientPopupBtn 0.3s linear forwards;
}

.popup__content .popup-btn:active {
  transform: scale(0.9);
  opacity: 0.9;
}

.popup__block.project-popup {
  padding: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px) clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #eeeceb 76.35%);
  max-width: clamp(600px, 600px + 36 * (100vw - 768px) / 672, 636px);
  width: 95%;
  border: 1px solid rgba(5, 5, 5, 0.15);
  border-radius: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
}

.popup__block.project-popup .popup__content {
  width: calc(100% + 12xp);
  padding-right: 12px;
}

.popup__block.project-popup .popup__content .simplebar-track.simplebar-vertical {
  width: 3px;
  background: #b0d8ce;
  border-radius: 4px;
  transform: none;
  top: clamp(40px, 40px + 46 * (100vw - 768px) / 672, 86px);
  bottom: 0;
  overflow: visible;
}

.popup__block.project-popup .popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: #b3b3b3;
  border-radius: 4px;
  width: 6px;
  left: -1.5px;
}

.popup__block.project-popup .popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

.popup__block.project-popup .popup__title {
  font-size: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  font-weight: 600;
  line-height: 1.12;
  letter-spacing: 0.02em;
  color: var(--cl-black);
  text-transform: none;
}

.popup__block.project-popup .popup__title:not(:last-child) {
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.popup__block.project-popup .popup__btn-close {
  position: absolute;
  top: clamp(16px, 16px + 16 * (100vw - 768px) / 672, 32px);
  right: clamp(16px, 16px + 16 * (100vw - 768px) / 672, 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  --size: clamp(28px, calc(28px + 4.00 * ((100vw - 768px) / 672)), 32px);
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  z-index: 10;
}

.popup__block.project-popup .popup__btn-close i {
  color: var(--cl-black);
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
  transition: 0.3s;
}

.popup__block.project-popup .popup__btn-close:hover i {
  color: var(--cl-purple);
  transition: 0.3s;
}

.popup__block.project-popup .popup-form__container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  grid-row-gap: 12px;
}

@media (max-width: 551px) {
  .popup__block.project-popup .popup-form__container {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
.popup__block.project-popup .popup-form__bottom {
  padding-top: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
}

.popup__block.project-popup .popup-form__bottom .meta-checkbox {
  max-width: calc(100% - 192px);
}

.popup__block.project-popup .popup-form__bottom .btn {
  min-width: 192px;
  background: var(--cl-black);
  border-color: var(--cl-black);
}

.popup__block.project-popup .popup-form__bottom .btn:after {
  display: none;
}

.popup__block.project-popup .popup-form__bottom .btn:hover {
  background-color: transparent;
}

@media (max-width: 551px) {
  .popup__block.project-popup .popup-form__bottom {
    flex-direction: column;
  }
  .popup__block.project-popup .popup-form__bottom .meta-checkbox {
    max-width: 100%;
    width: 100%;
  }
  .popup__block.project-popup .popup-form__bottom .btn {
    width: 100%;
  }
}
.popup__block.team-popup {
  padding: 0;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #eeeceb 76.35%);
  max-width: clamp(900px, 900px + 114 * (100vw - 768px) / 672, 1014px);
  width: 95%;
  border-radius: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
  border: 1px solid rgba(5, 5, 5, 0.1);
  overflow: hidden;
}

.popup__block.team-popup .team-popup__container {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}

.popup__block.team-popup .team-popup__content-container-decor {
  --arw: 411.2;
  --arh: 448.96;
  position: absolute;
  bottom: clamp(-102px, -40px + -62 * (100vw - 768px) / 672, -40px);
  right: clamp(-111px, -40px + -71 * (100vw - 768px) / 672, -40px);
  width: clamp(190px, 190px + 221 * (100vw - 768px) / 672, 411px);
  mix-blend-mode: soft-light;
}

.popup__block.team-popup .team-popup__card {
  width: clamp(300px, 300px + 35 * (100vw - 768px) / 672, 335px);
  min-width: clamp(300px, 300px + 35 * (100vw - 768px) / 672, 335px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: var(--cl-white);
  border-right: 1px solid rgba(5, 5, 5, 0.5);
}

@media (max-width: 640px) {
  .popup__block.team-popup .team-popup__card {
    display: none;
  }
}
.popup__block.team-popup .team-popup__card-image-container {
  width: 100%;
  overflow: hidden;
  padding-top: clamp(16px, 16px + 3 * (100vw - 768px) / 672, 19px);
  position: relative;
  background: var(--cl-aqua);
  overflow: hidden;
}

.popup__block.team-popup .team-popup__card-image-decor {
  --arw: 334.68;
  --arh: 424.36;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.popup__block.team-popup .team-popup__card-image {
  --arw: 335;
  --arh: 316;
  width: 100%;
  position: relative;
  z-index: 1;
}

.popup__block.team-popup .team-popup__card-image img {
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: bottom;
  object-position: bottom;
}

.popup__block.team-popup .team-popup__card-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px) clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px) clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  background: white;
  flex: 1;
}

.popup__block.team-popup .team-popup__card-title {
  font-size: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  font-weight: 600;
  line-height: 1.12;
  letter-spacing: 0.02em;
  color: var(--cl-black);
}

.popup__block.team-popup .team-popup__card-title:not(:last-child) {
  margin-bottom: 8px;
}

.popup__block.team-popup .team-popup__card-subtitle {
  font-size: clamp(16px, 16px + 1 * (100vw - 768px) / 672, 17px);
  font-weight: 600;
  line-height: 1.44;
  color: #9378c8;
}

.popup__block.team-popup .team-popup__card-subtitle:not(:last-child) {
  margin-bottom: 12px;
}

.popup__block.team-popup .team-popup__card-socials {
  margin-top: auto;
  grid-column-gap: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 158px);
}

.popup__block.team-popup .team-popup__card-social {
  font-size: clamp(36px, 36px + 4 * (100vw - 768px) / 672, 40px);
  color: var(--cl-black);
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup__block.team-popup .team-popup__card-social:hover {
  color: var(--cl-purple);
}

.popup__block.team-popup .team-popup__content-container {
  width: calc(100% - clamp(300px, 300px + 35 * (100vw - 768px) / 672, 335px));
  position: relative;
  padding-right: clamp(40px, 40px + 56 * (100vw - 768px) / 672, 96px);
  padding-top: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  padding-left: clamp(20px, 20px + 28 * (100vw - 768px) / 672, 48px);
  padding-bottom: 0;
}

@media (min-width: 641px) {
  .popup__block.team-popup .team-popup__content-container .team-popup__card-image-container {
    display: none;
  }
  .popup__block.team-popup .team-popup__content-container .team-popup__card-content {
    display: none;
  }
}
@media (max-width: 640px) {
  .popup__block.team-popup .team-popup__content-container {
    width: 100%;
  }
  .popup__block.team-popup .team-popup__content-container .team-popup__card-image-container {
    max-width: 390px;
    width: calc(100% - 24px);
    background: transparent;
  }
  .popup__block.team-popup .team-popup__content-container .team-popup__card-content {
    border-top: 1px solid rgba(5, 5, 5, 0.1);
    padding-left: 0;
    padding-right: 0;
    background: transparent;
  }
}
.popup__block.team-popup .team-popup__content {
  width: calc(100% + clamp(21px, 21px + 27 * (100vw - 768px) / 672, 48px));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: clamp(21px, 21px + 27 * (100vw - 768px) / 672, 48px);
  overflow: visible;
}

.popup__block.team-popup .team-popup__content .simplebar-track.simplebar-vertical {
  width: 3px;
  background: #b0d8ce;
  border-radius: 4px;
  transform: none;
  top: clamp(60px, 60px + 12 * (100vw - 768px) / 672, 72px);
  bottom: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  overflow: visible;
}

.popup__block.team-popup .team-popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar {
  background: #b3b3b3;
  border-radius: 4px;
  width: 6px;
  left: -1.5px;
}

.popup__block.team-popup .team-popup__content .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  content: none;
}

@media (min-width: 641px) {
  .popup__block.team-popup .team-popup__content {
    max-height: 55.44vh;
  }
}
.popup__block.team-popup .team-popup__title {
  font-size: clamp(32px, 32px + 12 * (100vw - 768px) / 672, 44px);
  font-weight: 600;
  line-height: 1.12;
  color: var(--cl-black);
}

.popup__block.team-popup .team-popup__title:not(:last-child) {
  margin-bottom: 24px;
}

.popup__block.team-popup .team-popup__text {
  width: 100%;
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  font-weight: 400;
  line-height: 1.48;
  font-family: var(--font-fourth);
  color: var(--cl-black);
}

.popup__block.team-popup .team-popup__text p:not(:last-child), .popup__block.team-popup .team-popup__text ul:not(:last-child), .popup__block.team-popup .team-popup__text ol:not(:last-child) {
  margin-bottom: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
}

.popup__block.team-popup .team-popup__text p:last-child, .popup__block.team-popup .team-popup__text ul:last-child, .popup__block.team-popup .team-popup__text ol:last-child {
  padding-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.popup__block.team-popup .team-popup__btn-close {
  position: absolute;
  top: clamp(16px, 16px + 16 * (100vw - 768px) / 672, 32px);
  right: clamp(16px, 16px + 16 * (100vw - 768px) / 672, 32px);
  display: flex;
  justify-content: center;
  align-items: center;
  --size: clamp(28px, calc(28px + 4.00 * ((100vw - 768px) / 672)), 32px);
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  z-index: 10;
}

.popup__block.team-popup .team-popup__btn-close i {
  color: var(--cl-black);
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
  transition: 0.3s;
}

.popup__block.team-popup .team-popup__btn-close:hover i {
  color: var(--cl-purple);
  transition: 0.3s;
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from)) translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }
  to {
    transform: translateY(var(--pp-default-translate-y-to)) translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}
@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }
  100% {
    transform: translateX(100%) skew(45deg);
  }
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
}

@media (max-width: 551px) {
  .buttons {
    width: 100%;
  }
  .buttons .btn {
    width: 100%;
  }
}
/* TODO: на головній розставити правильно класи для кнопок btn btn-border | btn btn-bg */
.btn {
  width: -moz-fit-content;
  width: fit-content;
  height: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
  padding: 0px 36px;
  font-size: clamp(17px, 17px + 1 * (100vw - 768px) / 672, 18px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  z-index: 1;
  position: relative;
  transition: 0.3s all;
}

.btn .btn__text {
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: 0.45px;
  transition: 0.3s all;
  min-width: -moz-max-content;
  min-width: max-content;
}

.btn.btn-border:after {
  position: absolute;
  inset: 0;
  content: "";
  z-index: -1;
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  border-radius: inherit;
}

.btn.btn-border .btn__text {
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn.btn-border:hover .btn__text {
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn.btn-bg {
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
}

.btn.btn-bg .btn__text {
  color: var(--cl-black);
}

.btn.btn-bg:hover {
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
}

.btn__small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 36px;
  background: var(--gradient);
  border-color: var(--cl-black);
  border-radius: 90px;
  padding: 12px 17px;
  transition: 0.3s;
}

.btn__small i {
  color: var(--cl-black);
  font-size: 13px;
  transition: 0.3s;
}

.btn__small:hover {
  background: var(--cl-black);
}

.btn__small:hover i {
  color: var(--gradient);
}

.btn__small:hover:after {
  background: inherit;
}

/* .btn {
  cursor: pointer;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  height: ac(56px, 50px);
  overflow: hidden;
  border-radius: 1400px;
  border: 1px solid;
  align-items: flex-start;
  padding: 15px 36px;
  z-index: 1;
  transition: .3s all;
  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
    transition: .3s all;
  }

  .btn__text {
    font-weight: 800;
    font-size: ac(18px, 14px);
    line-height: 1.4;
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    transition: .3s all;
  }

  .btn__icon {
    display: flex;
    transition: 0.3s;
  }

  &:hover {
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );

    .btn__text {
      background: var(--cl-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }

  &__gradient {
    transition: all 0.3s ease;
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );

    .btn__text {
      background: var(--cl-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transition: 0.3s;
    }

    &:hover {
      background: var(--cl-black);

      .btn__text {
        background: linear-gradient(
          57.88deg,
          rgba(174, 214, 204, 0.96) 17.35%,
          #eeeceb 76.35%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }
    }
  }

  &__small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 36px;
    background: var(--gradient);
    border-color: var(--cl-black);
    border-radius: 90px;
    padding: 12px 17px;
    transition: 0.3s;

    i {
      color: var(--cl-black);
      font-size: 13px;
      transition: 0.3s;
    }

    &:hover {
      background: var(--cl-black);

      i {
        color: var(--gradient);
      }

      &:after {
        background: inherit;
      }
    }
  }

  &.btn-bg {
    background: linear-gradient(
      46deg,
      rgba(174, 214, 204, 0.96) 0%,
      #eeeceb 100%
    );
    transition: .3s all;
    .btn__text {
      color: var(--cl-black);
      background: transparent;
      -webkit-text-fill-color: initial;
      transition: .3s all;
    }
  }
} */
.swiper-buttons {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  gap: 16px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;
}

.swiper-buttons .swiper-button {
  position: relative;
  transform: none;
  margin: 0;
  left: auto;
  right: auto;
  top: auto;
  bottom: auto;
  display: flex;
  width: 80px;
  height: 56px;
  justify-content: center;
  align-items: center;
  color: var(--cl-blue, #346c50);
  border: 1px solid var(--cl-white);
  border-radius: clamp(86px, 86px + 14 * (100vw - 768px) / 672, 100px);
  transition: 0.3s;
  background-color: var(--cl-black);
}

@media (max-width: 560px) {
  .swiper-buttons .swiper-button {
    width: 68px;
    height: 48px;
  }
}
.swiper-buttons .swiper-button::after {
  display: none;
}

.swiper-buttons .swiper-button::before {
  content: "\e905";
  display: block;
  font-family: "icomoon" !important;
  font-size: 24px;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #eeeceb 76.35%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

@media (max-width: 560px) {
  .swiper-buttons .swiper-button::before {
    font-size: 14px;
  }
}
.swiper-buttons .swiper-button.swiper-button-prev::before {
  content: "\e902";
}

.swiper-buttons .swiper-button:hover {
  background: var(--gradient);
  color: var(--cl-white, #fff);
}

.swiper-buttons .swiper-button:hover:before {
  background: var(--cl-black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.swiper-buttons .swiper-button.swiper-button-lock {
  display: none;
}

.meta-input label, .meta-input .label, .meta-select label, .meta-select .label, .meta-upload-file label, .meta-upload-file .label {
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: 1.32;
  font-weight: 600;
  padding-bottom: clamp(8px, 8px + 5 * (100vw - 768px) / 672, 13px);
  cursor: pointer;
  display: flex;
  letter-spacing: 1.28px;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  color: var(--cl-navy);
}

.meta-input label span, .meta-input .label span, .meta-select label span, .meta-select .label span, .meta-upload-file label span, .meta-upload-file .label span {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  display: inline-block;
  color: #dc2626;
  font-weight: 700;
  margin-left: clamp(1px, 1px + 1 * (100vw - 768px) / 672, 2px);
}

.dark-bg .meta-input label, .dark-bg .meta-input .label, .dark-bg .meta-select label, .dark-bg .meta-select .label, .dark-bg .meta-upload-file label, .dark-bg .meta-upload-file .label {
  color: var(--cl-white);
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 100px;
  height: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
}

.meta-input input, .meta-input textarea {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--cl-aqua);
  /* border: 1px solid #B9DFEF; */
  border-radius: 100px;
  outline: none;
  transition: box-shadow 0.25s ease, border-color 0.3s ease, caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
  padding: 10px clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  padding-left: clamp(17px, 17px + 9 * (100vw - 768px) / 672, 26px);
  height: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
  background: transparent;
  /*&.error {
    border-color: var(--cl-red);
    box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
  }*/
}

.meta-input input:-webkit-autofill, .meta-input input:-webkit-autofill:hover, .meta-input input:-webkit-autofill:focus, .meta-input input:-webkit-autofill:active, .meta-input textarea:-webkit-autofill, .meta-input textarea:-webkit-autofill:hover, .meta-input textarea:-webkit-autofill:focus, .meta-input textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-charcoal) !important;
}

.meta-input input::-moz-placeholder, .meta-input textarea::-moz-placeholder {
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: var(--cl-aqua-80);
  opacity: 1;
}

.meta-input input::placeholder, .meta-input textarea::placeholder {
  transition: all 0.3s ease;
  color: var(--cl-aqua-80);
  opacity: 1;
}

.meta-input input:focus, .meta-input textarea:focus {
  border-color: var(--cl-navy);
  caret-color: var(--cl-navy);
  box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
}

.meta-input textarea {
  /* padding-top: 16px; */
  /* min-height: ac(78px, 90px); */
  /* max-height: ac(78px, 90px); */
  overflow-y: auto;
  resize: none;
  /* clip-path: inset(-1px -1px -1px -1px round ac(39px, 28px)); */
  /* border-radius: ac(39px, 28px); */
  scrollbar-width: thin;
  scrollbar-color: var(--cl-primary) transparent;
}

.meta-input textarea::-webkit-scrollbar {
  width: 3px;
}

.meta-input textarea::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 2px;
}

.meta-input textarea::-webkit-scrollbar-thumb {
  background: var(--cl-primary);
  border-radius: 2px;
}

.meta-input__help, .meta-input__error {
  margin-top: 5px;
  font-family: var(--font-main);
  font-size: clamp(12px, 12px + 2 * (100vw - 768px) / 672, 14px);
  line-height: 1.2;
  font-weight: 700;
}

.meta-input__help {
  color: var(--cl-white);
}

/*&__error {
    color: var(--cl-red);
  }*/
.meta-input.form-type {
  height: auto;
  border-radius: 0;
}

.meta-input.form-type label, .meta-input.form-type .label {
  font-size: 16px;
  line-height: 1.48;
  font-weight: 400;
  padding-bottom: 4px;
  cursor: pointer;
  display: flex;
  letter-spacing: 0;
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  color: var(--cl-black);
}

.meta-input.form-type input, .meta-input.form-type textarea {
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid rgba(18, 18, 18, 0.12);
  padding-left: 19px;
  padding-right: 19px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-black);
}

.meta-input.form-type input:-webkit-autofill, .meta-input.form-type input:-webkit-autofill:hover, .meta-input.form-type input:-webkit-autofill:focus, .meta-input.form-type input:-webkit-autofill:active, .meta-input.form-type textarea:-webkit-autofill, .meta-input.form-type textarea:-webkit-autofill:hover, .meta-input.form-type textarea:-webkit-autofill:focus, .meta-input.form-type textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-black) !important;
}

.meta-input.form-type input::-moz-placeholder, .meta-input.form-type textarea::-moz-placeholder {
  color: var(--cl-black);
  opacity: 1;
}

.meta-input.form-type input::placeholder, .meta-input.form-type textarea::placeholder {
  color: var(--cl-black);
  opacity: 1;
}

.meta-input.form-type textarea {
  padding-top: 16px;
  min-height: clamp(90px, 90px + 6 * (100vw - 768px) / 672, 96px);
  max-height: clamp(90px, 90px + 6 * (100vw - 768px) / 672, 96px);
  overflow-y: auto;
  resize: none;
  -webkit-clip-path: inset(-1px -1px -1px -1px round 8px);
  clip-path: inset(-1px -1px -1px -1px round 8px);
  border-radius: 8px;
  scrollbar-width: thin;
  scrollbar-color: var(--cl-aqua) transparent;
}

.meta-input.profile-type input, .meta-input.profile-type textarea {
  border-radius: 8px;
  background-color: var(--cl-white);
  border: 1px solid rgba(53, 53, 63, 0.3);
  padding-left: 19px;
  padding-right: 19px;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--cl-charcoal);
}

.meta-input.profile-type input:-webkit-autofill, .meta-input.profile-type input:-webkit-autofill:hover, .meta-input.profile-type input:-webkit-autofill:focus, .meta-input.profile-type input:-webkit-autofill:active, .meta-input.profile-type textarea:-webkit-autofill, .meta-input.profile-type textarea:-webkit-autofill:hover, .meta-input.profile-type textarea:-webkit-autofill:focus, .meta-input.profile-type textarea:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--cl-charcoal) !important;
}

.meta-input.profile-type input::-moz-placeholder, .meta-input.profile-type textarea::-moz-placeholder {
  color: var(--cl-charcoal);
  opacity: 0.8;
}

.meta-input.profile-type input::placeholder, .meta-input.profile-type textarea::placeholder {
  color: var(--cl-charcoal);
  opacity: 0.8;
}

.meta-input.profile-type input:focus, .meta-input.profile-type textarea:focus {
  border-color: var(--cl-navy-80);
}

.meta-input.profile-type textarea {
  -webkit-clip-path: inset(-1px -1px -1px -1px round 8px);
  clip-path: inset(-1px -1px -1px -1px round 8px);
  border-radius: 8px;
  min-height: clamp(90px, 90px + 94 * (100vw - 768px) / 672, 184px);
  max-height: clamp(90px, 90px + 94 * (100vw - 768px) / 672, 184px);
}

.dark-bg .meta-input input:focus, .dark-bg .meta-input textarea:focus {
  border-color: var(--cl-aqua);
}

.is-safari .meta-input textarea::-webkit-scrollbar {
  width: auto;
  width: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-track {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.is-safari .meta-input textarea::-webkit-scrollbar-thumb {
  background: transparent none repeat 0 0/auto auto padding-box border-box scroll;
  background: initial;
  border-radius: 0;
  border-radius: initial;
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 100px;
  height: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
}

.meta-select select {
  opacity: 0;
  height: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
}

.meta-select .choices {
  width: 100%;
  margin-bottom: 0;
  overflow: visible;
}

.meta-select .choices:not(.is-open) {
  overflow: hidden;
}

.meta-select .choices__inner {
  transition: box-shadow 0.3s ease, border-color 0.3s ease, border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
  padding: 13px 29px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: clamp(50px, 50px + 6 * (100vw - 768px) / 672, 56px);
  background: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: var(--cl-aqua);
  border: none;
  border-radius: 100px !important;
  outline: none;
  /*&:hover {
    border-color: var(--cl-orange);
  }*/
  /*&.error {
    border-color: var(--cl-red);
    box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
  }*/
}

.meta-select .choices.is-open:not(.is-flipped) .choices__list--dropdown, .meta-select .choices.is-open:not(.is-flipped) .choices__list[aria-expanded] {
  margin-top: 8px;
}

.meta-select .choices.is-open.is-flipped .choices__list--dropdown, .meta-select .choices.is-open.is-flipped .choices__list[aria-expanded] {
  margin-bottom: 8px;
}

.meta-select .choices__item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta-select .choices__placeholder {
  color: var(--cl-aqua-80);
  opacity: 1;
  background: transparent;
}

.meta-select .choices__list {
  padding: 0;
  background-color: #121212;
}

.meta-select .choices__list .choices__item {
  padding-right: clamp(30px, 30px + 6 * (100vw - 768px) / 672, 36px);
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: var(--cl-aqua);
  transition: all 0.3s ease;
  text-decoration: underline;
  text-decoration-color: transparent;
}

.meta-select .choices__list .choices__item.is-selected {
  color: var(--cl-aqua);
}

.meta-select .choices__list--single {
  background-color: transparent !important;
}

.meta-select .choices__list--single .choices__item {
  color: var(--cl-aqua);
}

.meta-select .choices__list--multiple {
  color: var(--cl-aqua-80);
}

.meta-select .choices__list--multiple .choices__item--selectable {
  display: none;
}

.meta-select .choices__list--multiple + .choices__input {
  display: none;
}

.meta-select .choices__list--dropdown .choices__item {
  color: var(--cl-aqua-80);
  text-decoration: none !important;
}

.meta-select .choices__list--dropdown .choices__item:hover {
  /* &:not(.is-selected) {
    color: var(--cl-grey) !important;
    text-decoration: none !important;
  } */
}

.meta-select .choices[data-type*=select-one], .meta-select .choices[data-type*=select-multiple] {
  cursor: pointer;
}

.meta-select .choices[data-type*=select-one]:after, .meta-select .choices[data-type*=select-multiple]:after {
  border: none;
  content: "\e900";
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: absolute;
  right: 16.5px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.15s ease-in-out;
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  width: clamp(11px, 11px + 9 * (100vw - 768px) / 672, 20px);
  height: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  margin-top: 0;
  font-size: clamp(8px, 8px + 3 * (100vw - 768px) / 672, 11px);
  z-index: 2;
  color: #fff;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.meta-select .choices[data-type*=select-one]:before, .meta-select .choices[data-type*=select-multiple]:before {
  position: absolute;
  width: clamp(38px, 38px + 10 * (100vw - 768px) / 672, 48px);
  height: clamp(38px, 38px + 10 * (100vw - 768px) / 672, 48px);
  border: 1px solid var(--cl-aqua);
  border-radius: 50%;
  background: transparent;
  content: "";
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  transition: 0.3s all;
}

.meta-select .choices.is-open::after {
  /* transform: scaleY(-1) translateY(-50%); */
  /* top: 36%; */
  transform: rotate(180deg) translateY(50%);
  /* top: 11%; */
}

.meta-select .choices__list--dropdown .choices__item--selectable.is-highlighted, .meta-select .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: transparent;
  /* &:not(.is-selected) {
    color: var(--cl-black);
  } */
}

.meta-select .choices__list--dropdown, .meta-select .choices .choices__list[aria-expanded] {
  border: 1px solid var(--cl-aqua);
  border-radius: 0;
}

.meta-select .choices[data-type*=select-one] .choices__input {
  /* margin: 2px;
  max-width: calc(100% - 4px);
  border: 1px solid var(--cl-black);
  border-radius: 0;
  background: var(--cl-white);
  color: var(--cl-black);
  font-size: ac(16px, 14px);
  line-height: 1.2;
  font-weight: 500;
  font-family: var(--font-main); */
}

.meta-select .choices[data-type*=select-multiple] .choices__inner {
  cursor: pointer;
}

.dark-bg .meta-select label {
  color: var(--cl-white);
}

.dark-bg .meta-select .choices__inner {
  border-color: var(--cl-white);
}

.dark-bg .meta-select .choices[data-type*=select-one], .dark-bg .meta-select .choices[data-type*=select-multiple] {
  cursor: pointer;
}

.dark-bg .meta-select .choices[data-type*=select-one]:after, .dark-bg .meta-select .choices[data-type*=select-multiple]:after {
  color: var(--cl-white);
}

.dark-bg .meta-select .choices[data-type*=select-one]:before, .dark-bg .meta-select .choices[data-type*=select-multiple]:before {
  border: 1px solid var(--cl-white);
  border-radius: 50%;
  background: transparent;
}

.dark-bg .meta-select .choices__list--single .choices__item--selectable {
  color: var(--cl-white) !important;
}

.password-input {
  position: relative;
}

.password-input__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  right: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  bottom: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  position: absolute;
  z-index: 10;
  --size: clamp(22px, calc(22px + 2.00 * ((100vw - 768px) / 672)), 24px);
  width: var(--size);
  height: var(--size);
  cursor: pointer;
  color: var(--cl-charcoal);
  transition: 0.3s;
}

.password-input__btn i {
  font-size: 16px;
}

.password-input__btn i.icon-not-show {
  font-size: 18px;
}

.password-input__btn:hover {
  color: var(--cl-navy);
}

/* .meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  label,
  .label {
    color: #757575;
    padding-bottom: ac(16px, 12px);
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    border: 1px solid var(--cl-navy);
    padding: ac(14px, 10px) ac(28px, 24px);
    border-radius: 12px;
    transition: all 0.3s ease;
    background: var(--cl-white);
    cursor: pointer;
    position: relative;
    height: ac(56px, 48px);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-navy);
      transition: 0.3s;
    }

    &:hover {
      background-color: var(--cl-aqua);
      border-color: var(--cl-charcoal);
      color: var(--cl-charcoal);

      span {
        color: var(--cl-charcoal);
      }
    }
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-style: italic;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-primary);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
} */
.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.meta-upload-file label, .meta-upload-file .label {
  font-size: 16px;
  line-height: 1.48;
  font-weight: 400;
  padding-bottom: 4px;
  cursor: pointer;
  display: flex;
  letter-spacing: 0;
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  color: var(--cl-black);
}

.meta-upload-file__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: clamp(10px, 10px + 7 * (100vw - 768px) / 672, 17px);
  width: 100%;
}

.meta-upload-file__container:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 3 * (100vw - 768px) / 672, 13px);
}

.meta-upload-file__input {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(16px, 16px + 1 * (100vw - 768px) / 672, 17px);
  line-height: normal;
  font-weight: 600;
  font-family: var(--font-main);
  color: var(--cl-white);
  border: 2px solid var(--cl-black);
  border-radius: 28px;
  background: var(--cl-black);
  transition: all 0.3s ease;
  padding: 4px clamp(18px, 18px + 10 * (100vw - 768px) / 672, 28px);
  cursor: pointer;
  position: relative;
  height: clamp(40px, 40px + 4 * (100vw - 768px) / 672, 44px);
  text-transform: uppercase;
}

.meta-upload-file__input input {
  position: absolute;
  inset: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.meta-upload-file__input input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

.meta-upload-file__input span {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--cl-white);
}

.meta-upload-file__input:hover {
  background-color: transparent;
  color: var(--cl-black);
}

.meta-upload-file__file-name {
  max-width: calc(100% - clamp(114px, 114px + 8 * (100vw - 768px) / 672, 122px));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-second);
  font-size: clamp(15px, 15px + 2 * (100vw - 768px) / 672, 17px);
  font-weight: 300;
  color: var(--cl-black);
}

.meta-upload-file__info {
  margin-top: 6px;
  font-size: clamp(12px, 12px + 1 * (100vw - 768px) / 672, 13px);
  line-height: normal;
  font-weight: 300;
  font-style: italic;
  color: var(--cl-black);
}

.meta-upload-file.drag .meta-upload-file__input {
  background-color: var(--cl-aqua);
  box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-black)/0.3);
}

.meta-upload-file.drop .meta-upload-file__input {
  border-style: solid;
}

.meta-upload-file.drop:not(.done) .meta-upload-file__input {
  transform: scale(0.9);
}

.meta-upload-file.second-type .meta-upload-file__input {
  border-color: var(--cl-purple);
  background: var(--cl-purple);
}

.meta-upload-file.second-type .meta-upload-file__input:hover {
  background-color: transparent;
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.meta-checkbox-list__item:not(:last-child) {
  margin-bottom: 8px;
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.meta-checkbox__box {
  --size: clamp(24px, calc(24px + 8.00 * ((100vw - 768px) / 672)), 32px);
  border-radius: clamp(3px, 3px + 2 * (100vw - 768px) / 672, 5px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  border: 1px solid rgba(5, 5, 5, 0.5);
  background-color: transparent;
  margin-right: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.meta-checkbox__box:hover {
  border-color: var(--cl-purple);
}

.meta-checkbox__box::before {
  content: "\e90d";
  font-family: "icomoon" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  opacity: 0;
  font-size: 14px;
  display: none;
}

.meta-checkbox__box .check-clip, .meta-checkbox__box .circle-clip {
  width: 75%;
  height: 75%;
  background-color: var(--cl-black);
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.meta-checkbox__box .check-clip {
  -webkit-clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
  clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
}

.meta-checkbox__box .circle-clip {
  -webkit-clip-path: circle(50%);
  clip-path: circle(50%);
}

.meta-checkbox__box i {
  /* opacity: 0;
  font-size: ac(10px, 8px);
  color: var(--cl-charcoal);
  transition: opacity 0.3s ease, color 0.3s ease; */
}

.meta-checkbox input {
  display: none;
}

.meta-checkbox__text {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.4;
  color: rgba(5, 5, 5, 0.8);
}

.meta-checkbox__text:hover {
  color: rgba(56, 65, 84, 0.805);
}

.meta-checkbox__text a {
  font: inherit;
  color: var(--cl-black);
  text-decoration: underline;
  text-decoration-color: var(--cl-black);
  transition: all 0.25s ease;
  font-weight: 600;
}

.meta-checkbox__text a:hover {
  color: var(--cl-purple);
  text-decoration-color: var(--cl-purple);
}

.meta-checkbox input:checked ~ .meta-checkbox__box {
  border-color: var(--cl-white);
  background-color: var(--cl-white);
}

.meta-checkbox input:checked ~ .meta-checkbox__box::before {
  opacity: 1;
}

.meta-checkbox input:checked ~ .meta-checkbox__box .check-clip, .meta-checkbox input:checked ~ .meta-checkbox__box .circle-clip, .meta-checkbox input:checked ~ .meta-checkbox__box i {
  opacity: 1;
  color: var(--cl-black);
}

.meta-checkbox input:checked ~ .meta-checkbox__box:hover {
  border-color: var(--cl-purple);
}

.not-found {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
  z-index: 5;
  padding: 0;
  margin: 0;
}

.not-found__title {
  font-size: clamp(100px, 100px + 20 * (100vw - 768px) / 672, 120px);
}

.not-found:before {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
  z-index: 3;
}

.not-found__content {
  margin-top: 27px;
}

.not-found__content p {
  margin: 0;
}

.not-found__button .btn {
  min-width: 200px;
}

.not-found__bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.not-found__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.cookies {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.cookies:before {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
  z-index: 3;
}

.cookies p {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.cookies h1 {
  font-size: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  color: var(--cl-aqua);
  font-weight: 700;
}

.cookies h2 {
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
  color: var(--cl-aqua);
  font-weight: 600;
  margin-top: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.cookies h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  color: var(--cl-aqua);
  font-weight: 500;
}

.cookies h3:nth-of-type(2) {
  margin-top: 40px;
}

.cookies h3:nth-of-type(4) {
  margin-top: 40px;
}

.cookies .text-red-400 {
  color: var(--cl-aqua);
}

.cookies__bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.cookies__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.policy {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.policy:before {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
  z-index: 3;
}

.policy p {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.policy h1 {
  font-size: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  color: var(--cl-aqua);
  font-weight: 700;
}

.policy h2 {
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
  color: var(--cl-aqua);
  font-weight: 600;
  margin-top: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.policy h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  color: var(--cl-aqua);
  font-weight: 500;
}

.policy h3:nth-of-type(2) {
  margin-top: 40px;
}

.policy h3:nth-of-type(4) {
  margin-top: 40px;
}

.policy .text-red-400 {
  color: var(--cl-aqua);
}

.policy__bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.policy__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.terms {
  position: relative;
  overflow: hidden;
  z-index: 10;
}

.terms:before {
  position: absolute;
  inset: 0;
  background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
  z-index: 3;
}

.terms p {
  font-size: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.terms h1 {
  font-size: clamp(32px, 32px + 8 * (100vw - 768px) / 672, 40px);
  color: var(--cl-aqua);
  font-weight: 700;
}

.terms h2 {
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
  color: var(--cl-aqua);
  font-weight: 600;
  margin-top: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
}

.terms h3 {
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  color: var(--cl-aqua);
  font-weight: 500;
}

.terms h3:nth-of-type(2) {
  margin-top: 40px;
}

.terms h3:nth-of-type(4) {
  margin-top: 40px;
}

.terms .text-red-400 {
  color: var(--cl-aqua);
}

.terms__bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.terms__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.team-list {
  padding-top: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
}

.team-card {
  position: relative;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  background: var(--cl-white);
  overflow: hidden;
}

.team-card__top {
  position: relative;
  width: 100%;
  overflow: hidden;
  background: var(--cl-aqua);
  min-height: 335px;
}

.team-card__top-bg {
  position: relative;
  inset: 0;
}

.team-card__top-bg svg {
  width: 100%;
  height: 100%;
}

.team-card__top-image {
  position: absolute;
  inset: 0;
  z-index: 2;
  padding: 19px 0 0;
}

.team-card__top-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: bottom;
  object-position: bottom;
}

@media (max-width: 560px) {
  .team-card__top-image {
    padding: 18px 0 0;
  }
}
.team-card__bottom {
  padding: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px) clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
  font-weight: 600;
}

.team-card__bottom-name {
  color: var(--cl-black);
  font-size: clamp(28px, 28px + 4 * (100vw - 768px) / 672, 32px);
}

.team-card__bottom-position {
  font-size: clamp(17px, 18px + -1 * (100vw - 768px) / 672, 18px);
  color: var(--cl-purple);
}

.team-card__bottom-social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

.team-card__bottom-social a svg {
  transition: 0.3s ease;
}

.team-card__bottom-social a:hover svg {
  transform: scale(1.1);
}

.team-card__bottom-social span {
  color: var(--cl-black);
  font-size: clamp(17px, 17px + 3 * (100vw - 768px) / 672, 20px);
  line-height: 1.4;
  font-weight: 800;
  padding-left: 32px;
  padding-right: 12px;
  white-space: nowrap;
}

.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: clamp(162px, 162px + 38 * (100vw - 768px) / 672, 200px);
  padding-bottom: clamp(48px, 48px + 95 * (100vw - 768px) / 672, 143px);
}

.first-section__bg-image {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.first-section__bg-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.first-section:before {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(280.11deg, rgba(18, 18, 18, 0.4) 7.57%, #121212 100%);
  z-index: 4;
}

.first-section__wrapper {
  position: relative;
  z-index: 5;
}

.first-section__text {
  max-width: clamp(480px, 480px + 120 * (100vw - 768px) / 672, 600px);
}

.first-section__title h1 span {
  font-weight: 700;
  font-size: clamp(54px, 54px + 40 * (100vw - 768px) / 672, 94px);
}

@media (max-width: 768px) {
  .first-section__title {
    width: 100%;
  }
}
.first-section__content {
  /* width: 51.02%; */
  padding-top: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  font-weight: 600;
  line-height: 156%;
}

.first-section__content p {
  color: var(--cl-aqua);
  line-height: 1.5;
}

@media (max-width: 768px) {
  .first-section__content {
    width: 100%;
  }
}
.first-section__button {
  margin-top: 48px;
}

.first-section__bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.first-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.first-section__line {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.first-section.for-candidates {
  padding-top: clamp(160px, 160px + 76 * (100vw - 768px) / 672, 236px);
  padding-bottom: clamp(82px, 82px + 74 * (100vw - 768px) / 672, 156px);
}

.first-section.for-candidates:before {
  display: none;
}

.first-section.for-candidates .first-section__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

@media (max-width: 780px) {
  .first-section.for-candidates .first-section__wrap {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 26px;
  }
}
.first-section.for-candidates .first-section__text {
  max-width: clamp(410px, 410px + 294 * (100vw - 768px) / 672, 704px);
}

@media (max-width: 780px) {
  .first-section.for-candidates .first-section__text {
    max-width: 768px;
  }
}
.first-section.for-candidates .first-section__title {
  font-size: clamp(42px, 42px + 26 * (100vw - 768px) / 672, 68px);
}

.first-section.for-candidates .first-section__title span {
  font-size: clamp(48px, 48px + 32 * (100vw - 768px) / 672, 80px);
}

.first-section.for-candidates .first-section__image {
  margin-right: clamp(32px, 32px + 101 * (100vw - 768px) / 672, 133px);
  max-width: clamp(130px, 130px + 70 * (100vw - 640px) / 800, 200px);
}

@media (max-width: 640px) {
  .first-section.for-candidates .first-section__image {
    max-width: clamp(84px, 84px + 46 * (100vw - 320px) / 320, 130px);
  }
}
.first-section.for-candidates .first-section__image img {
  width: 100%;
  height: 100%;
}

.first-section.clients-page .cont {
  max-width: 1232px;
  width: 86.3888888889%;
}

.first-section.clients-page.first-section {
  padding: 0;
}

.first-section.clients-page.first-section:before {
  content: "";
  position: absolute;
  inset: 0;
  background: #B0D8CE;
  mix-blend-mode: color;
  z-index: 4;
}

.first-section.clients-page.first-section:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
}

.first-section.clients-page .first-section__wrapper {
  padding-top: clamp(162px, 162px + 78 * (100vw - 768px) / 672, 240px);
  padding-bottom: clamp(80px, 80px + 28 * (100vw - 768px) / 672, 108px);
}

.first-section.clients-page .first-section__text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  min-width: 100%;
  gap: clamp(42px, 42px + 14 * (100vw - 768px) / 672, 56px);
}

.first-section.clients-page .first-section__title {
  width: 100%;
  max-width: clamp(478px, 478px + 226 * (100vw - 768px) / 672, 704px);
}

.first-section.clients-page .first-section__title.heading-1 {
  font-size: clamp(42px, 42px + 26 * (100vw - 640px) / 800, 68px);
}

.first-section.clients-page .first-section__title.heading-1 span {
  font-size: clamp(44px, 44px + 36 * (100vw - 640px) / 800, 80px);
}

.first-section.clients-page .first-section__content {
  margin-left: auto;
  padding: 0;
  font-weight: 400;
  font-size: clamp(20px, 20px + 2 * (100vw - 768px) / 672, 22px);
  width: 44.04%;
}

.first-section.clients-page .first-section__content p {
  color: var(--cl-white);
}

@media (max-width: 1200px) {
  .first-section.clients-page .first-section__content {
    margin: 0;
    width: 100%;
    font-weight: 600;
  }
}
.first-section.clients-page .first-section__border {
  background: var(--cl-aqua);
  height: 4px;
  width: 100%;
}

.who-we-section {
  background: linear-gradient(29.92deg, #AED6CC -20.99%, #EEECEB 87.62%);
  position: relative;
  z-index: 5;
}

.who-we-section__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  padding-top: clamp(24px, 24px + 84 * (100vw - 768px) / 672, 108px);
  padding-bottom: clamp(40px, 40px + 68 * (100vw - 768px) / 672, 108px);
  z-index: 10;
}

@media (max-width: 1200px) {
  .who-we-section__wrapper {
    flex-direction: column;
  }
}
.who-we-section__content {
  color: var(--cl-black);
}

.who-we-section__content p {
  font-size: clamp(17px, 17px + 3 * (100vw - 768px) / 672, 20px);
  line-height: 144%;
}

.who-we-section__content p:first-of-type {
  padding-top: 24px;
}

.who-we-section__subtitle {
  padding-top: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 500;
  font-size: clamp(20px, 20px + 6 * (100vw - 768px) / 672, 26px);
  line-height: 140%;
}

.who-we-section__image {
  position: relative;
  min-width: 46.41%;
  z-index: 5;
  border-radius: clamp(14px, 14px + 6 * (100vw - 768px) / 672, 20px);
  overflow: hidden;
}

.who-we-section__image:before {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(18, 18, 18, 0.6980392157);
  z-index: 2;
}

@media (max-width: 450px) {
  .who-we-section__image {
    width: 100%;
    max-height: 245px;
  }
}
@media (max-width: 1199px) {
  .who-we-section__image {
    aspect-ratio: 2/1;
  }
}
.who-we-section__image-logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease;
  z-index: 3;
}

@media (max-width: 560px) {
  .who-we-section__image-logo svg {
    width: 120px;
    height: 129px;
  }
}
.who-we-section__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  mix-blend-mode: soft-light;
}

.who-we-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.our-team {
  position: relative;
  z-index: 10;
}

.our-team__wrapper {
  padding-top: clamp(60px, 60px + 20 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(80px, 120px + -40 * (100vw - 768px) / 672, 120px);
  position: relative;
  z-index: 8;
}

.our-team__title.heading-2 {
  font-size: clamp(40px, 40px + 16 * (100vw - 768px) / 672, 56px);
}

.our-team__title span {
  font-size: clamp(44px, 44px + 16 * (100vw - 768px) / 672, 60px);
}

.our-team__slider .swiper {
  overflow: visible;
}

.our-team__buttons {
  position: absolute;
  top: 90px;
  right: 0;
}

@media (max-width: 560px) {
  .our-team__buttons {
    bottom: 40px;
    left: 0;
  }
}
@media (max-width: 1200px) {
  .our-team__buttons {
    top: 72px;
  }
}
@media (max-width: 880px) {
  .our-team__buttons {
    top: 60px;
  }
}
@media (max-width: 560px) {
  .our-team__buttons {
    top: auto;
    right: auto;
    left: 0;
    bottom: 40px;
  }
}
.our-team__bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.our-team__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.our-expertise {
  z-index: 2;
}

.our-expertise__wrapper {
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(120px, 120px + 80 * (100vw - 768px) / 672, 200px);
  z-index: 4;
  position: relative;
}

.our-expertise__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 920px) {
  .our-expertise__top {
    gap: 24px;
    flex-direction: column;
  }
}
.our-expertise__title h2 span {
  font-weight: 700;
  font-size: clamp(44px, 44px + 16 * (100vw - 768px) / 672, 60px);
}

.our-expertise__list {
  padding-top: clamp(32px, 32px + 48 * (100vw - 768px) / 672, 80px);
}

.our-expertise__button {
  z-index: 6;
}

.our-expertise__button .btn__small {
  height: 40px;
  background: var(--cl-black);
  background: transparent;
}

.our-expertise__button .btn__small i {
  color: var(--gradient);
}

.our-expertise__button .btn__small:after {
  background: inherit;
}

.our-expertise__button .btn__small:hover {
  background: var(--gradient);
}

.our-expertise__button .btn__small:hover i {
  color: var(--cl-black);
}

.our-expertise__item {
  position: relative;
  max-width: 495px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px) clamp(28px, 28px + 12 * (100vw - 768px) / 672, 40px);
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--cl-aqua);
  overflow: hidden;
  z-index: 6;
  min-height: 372px;
  height: auto;
  transition: 0.3s ease-in;
}

@media (max-width: 640px) {
  .our-expertise__item {
    max-width: clamp(318px, 318px + 177 * (100vw - 375px) / 265, 495px);
    min-height: 380px;
  }
}
@media (max-width: 374px) {
  .our-expertise__item {
    max-width: clamp(280px, 280px + 38 * (100vw - 320px) / 55, 318px);
  }
}
.our-expertise__item:before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 3;
  background: linear-gradient(293.19deg, rgba(5, 5, 5, 0.6) 0%, #050505 100%);
  transition: 0.3s ease;
}

.our-expertise__item:after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 2;
  background: #91F3DA;
  mix-blend-mode: color;
}

.our-expertise__item:hover:before {
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
}

.our-expertise__item:hover .our-expertise__item-title {
  color: var(--cl-black);
}

.our-expertise__item:hover .our-expertise__item-border {
  background: var(--cl-black);
}

.our-expertise__item:hover .our-expertise__item-content {
  color: var(--cl-black);
}

.our-expertise__item-title {
  position: relative;
  color: var(--cl-white);
  z-index: 5;
  transition: 0.3s ease;
}

.our-expertise__item-border {
  position: relative;
  width: 100%;
  height: 1px;
  background: var(--cl-aqua);
  transition: 0.3s ease;
  z-index: 5;
}

.our-expertise__item-content {
  color: var(--cl-aqua);
  position: relative;
  z-index: 5;
  transition: 0.3s ease;
  font-size: clamp(17px, 17px + 5 * (100vw - 768px) / 672, 22px);
  line-height: 1.44;
  max-height: 380px;
  overflow: auto;
}

@media (max-width: 640px) {
  .our-expertise__item-content {
    max-height: 296px;
  }
}
.our-expertise__bg {
  position: absolute;
  inset: 0;
  z-index: 2;
}

.our-expertise__bg img {
  width: 100%;
  height: 100%;
}

.our-expertise__buttons {
  position: absolute;
  right: 0;
  bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
}

@media (max-width: 1200px) {
  .our-expertise__buttons {
    left: 0;
    right: auto;
  }
}
.our-expertise__scrollbar {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.3019607843);
}

.our-expertise__scrollbar.swiper-scrollbar-horizontal {
  position: absolute;
  bottom: 108px;
  width: 82.35%;
  height: 3px;
}

.our-expertise__scrollbar .swiper-scrollbar-drag {
  height: 6px;
  background: var(--cl-aqua);
}

@media (max-width: 1200px) {
  .our-expertise__scrollbar {
    display: none;
  }
}
.our-expertise__slider .swiper {
  overflow: visible;
  max-width: 495px;
  margin: 0;
}

@media (max-width: 640px) {
  .our-expertise__slider .swiper {
    max-width: clamp(318px, 318px + 177 * (100vw - 375px) / 265, 495px);
  }
}
@media (max-width: 374px) {
  .our-expertise__slider .swiper {
    max-width: clamp(280px, 280px + 38 * (100vw - 320px) / 55, 318px);
  }
}
.testimonials {
  position: relative;
  z-index: 3;
  background: #121212;
  overflow: hidden;
}

.testimonials__wrapper {
  position: relative;
  padding-top: clamp(40px, 40px + 80 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(39px, 39px + 41 * (100vw - 768px) / 672, 80px);
  z-index: 4;
}

.testimonials-button {
  padding-top: clamp(32px, 32px + 32 * (100vw - 768px) / 672, 64px);
  max-width: -moz-max-content;
  max-width: max-content;
  margin: 0 auto;
}

.testimonials__title {
  display: flex;
  justify-content: center;
}

.testimonials-slider {
  margin-top: clamp(80px, 104px + -24 * (100vw - 768px) / 672, 104px);
}

@media (min-width: 1200px) {
  .testimonials-slider {
    position: relative;
  }
}
.testimonials-slider .swiper-wrapper {
  position: static;
}

.testimonials-slider .swiper {
  max-width: clamp(650px, 650px + 158 * (100vw - 768px) / 672, 808px);
  overflow: visible;
  position: static;
}

/* margin: 0; */
@media (max-width: 1200px) {
  .testimonials-slider .swiper {
    /* width: 100%; */
  }
}
@media (min-width: 992px) {
  .testimonials-slider .swiper {
    position: relative;
  }
}
.testimonials-slider .swiper-buttons {
  position: absolute;
  top: clamp(110px, 110px + 25 * (100vw - 460px) / 532, 135px);
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 342px) {
  .testimonials-slider .swiper-buttons {
    top: 140px;
  }
}
@media (min-width: 992px) {
  .testimonials-slider .swiper-buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% + clamp(8px, 8px + 40 * (100vw - 768px) / 672, 48px));
    justify-content: space-between;
  }
  .testimonials-slider .swiper-buttons .swiper-button.swiper-button-prev {
    transform: translateX(-100%);
  }
  .testimonials-slider .swiper-buttons .swiper-button.swiper-button-next {
    transform: translateX(100%);
  }
}
.testimonials-slider .swiper-slide-prev, .testimonials-slider .swiper-slide-next {
  background: #050505;
  border: 1px solid var(--cl-aqua);
}

.testimonials-slider .swiper-slide-prev .content-element, .testimonials-slider .swiper-slide-next .content-element {
  color: var(--cl-aqua);
}

.testimonials-list {
  display: flex;
  align-items: center;
}

.testimonials-card {
  transition: all 0.3s ease-in;
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px) clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  border-radius: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
  max-width: clamp(650px, 650px + 158 * (100vw - 768px) / 672, 808px);
  min-height: 455px;
  height: auto;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
}

@media (max-width: 900) {
  .testimonials-card {
    min-height: 335px;
  }
}
.testimonials-card__content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--cl-black);
  font-size: clamp(17px, 17px + 7 * (100vw - 768px) / 672, 24px);
  line-height: 1.48;
  transition: all 0.3s ease-in;
}

.testimonials-card__content-name {
  font-weight: 700;
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  margin-top: auto;
}

.testimonials-card__content-position {
  font-size: clamp(16px, 17px + -1 * (100vw - 768px) / 672, 17px);
  padding-top: clamp(6px, 6px + 2 * (100vw - 768px) / 672, 8px);
}

@media (max-width: 768px) {
  .testimonials-card__content p {
    line-height: 1.4;
  }
}
.testimonials__bg {
  position: absolute;
  inset: 0;
  z-index: 1;
  mix-blend-mode: soft-light;
}

.testimonials__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonials.clients-page.testimonials {
  background: var(--gradient);
}

.testimonials.clients-page .testimonials__wrapper {
  padding-bottom: 54px;
}

@media (max-width: 1200px) {
  .testimonials.clients-page .testimonials-card {
    min-height: 540px;
    height: auto;
  }
}
.testimonials.clients-page .testimonials__title {
  color: var(--cl-black);
  text-align: center;
}

.testimonials.clients-page .testimonials-slider {
  margin-top: 64px;
}

@media (max-width: 1200px) {
  .testimonials.clients-page .testimonials-slider {
    margin-top: 104px;
  }
}
.testimonials.clients-page .testimonials-slider .swiper-slide-prev, .testimonials.clients-page .testimonials-slider .swiper-slide-next {
  background: transparent;
  border: 1px solid var(--cl-black);
}

.testimonials.clients-page .testimonials-slider .swiper-slide-prev .content-element, .testimonials.clients-page .testimonials-slider .swiper-slide-next .content-element {
  color: var(--cl-black);
}

.testimonials.clients-page .testimonials-slider .swiper-slide-active {
  background: var(--cl-black);
}

.testimonials.clients-page .testimonials-slider .swiper-slide-active .content-element {
  color: var(--cl-white);
}

.testimonials.clients-page .testimonials-slider .swiper-slide-active .testimonials-card__content-position {
  color: var(--cl-aqua);
}

.testimonials.clients-page .testimonials-slider .swiper-button {
  background: var(--gradient);
  border-color: var(--cl-black);
}

.testimonials.clients-page .testimonials-slider .swiper-button:before {
  font-size: 17px;
  background: var(--cl-black);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.testimonials.clients-page .testimonials-slider .swiper-button:hover {
  background: var(--cl-black);
  border-color: var(--cl-black);
}

.testimonials.clients-page .testimonials-slider .swiper-button:hover:before {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.testimonials.clients-page .testimonials-button .btn__gradient {
  border-color: var(--cl-black);
}

.testimonials.clients-page .testimonials-button .btn__gradient:hover {
  background: var(--cl-black);
}

.testimonials.clients-page .testimonials-button .btn__gradient:hover:after {
  background: var(--cl-black);
  color: var(--cl-black);
}

.cooperate {
  position: relative;
  z-index: 5;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
}

.cooperate__wrapper {
  position: relative;
  padding-top: clamp(56px, 56px + 64 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(56px, 56px + 64 * (100vw - 768px) / 672, 120px);
  z-index: 6;
}

.cooperate .js-marquee {
  display: flex;
  gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

.cooperate .js-marquee__wrapper {
  display: flex;
}

.cooperate .js-marquee-wrapper {
  display: flex;
}

.cooperate__title {
  color: var(--cl-black);
  margin-bottom: clamp(40px, 40px + 24 * (100vw - 768px) / 672, 64px);
}

.cooperate__logo {
  display: grid;
  place-items: center;
  padding: 5px;
  background-color: var(--cl-white);
  width: 236px;
  height: 140px;
  border: 1px solid var(--cl-aqua);
  border-radius: 20px;
  overflow: hidden;
}

.cooperate__logo img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

@media (min-width: 1536px) {
  .cooperate .cont {
    max-width: 100%;
    padding: 0;
  }
}
.cooperate__marquee {
  display: flex;
  gap: clamp(12px, 12px + 4 * (100vw - 768px) / 672, 16px);
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
}

.cooperate__bg {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.cooperate__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.opportunities {
  padding-top: clamp(72px, 72px + 8 * (100vw - 768px) / 672, 80px);
  padding-bottom: clamp(72px, 72px + 8 * (100vw - 768px) / 672, 80px);
}

.opportunities-bg {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.opportunities-bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.opportunities-nav {
  margin-bottom: clamp(40px, 40px + 40 * (100vw - 768px) / 672, 80px);
}

.opportunities-nav__form {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 840px) {
  .opportunities-nav__form {
    flex-wrap: wrap;
    gap: 14px;
    justify-content: center;
  }
}
.opportunities-nav__form .meta-input {
  max-width: clamp(200px, 200px + 192 * (100vw - 768px) / 672, 392px);
  margin-right: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  width: 100%;
  --gradient: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
}

@media (max-width: 840px) {
  .opportunities-nav__form .meta-input {
    max-width: 400px;
    margin-right: 0;
  }
}
@media (max-width: 520px) {
  .opportunities-nav__form .meta-input {
    max-width: 100%;
  }
}
.opportunities-nav__form .meta-select {
  --gradient: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  width: 100%;
  margin-right: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

.opportunities-nav__form .meta-select.job-type {
  max-width: clamp(240px, 240px + 48 * (100vw - 768px) / 672, 288px);
}

.opportunities-nav__form .meta-select.job-sector {
  max-width: clamp(260px, 260px + 50 * (100vw - 768px) / 672, 310px);
  margin-right: 0;
}

@media (max-width: 840px) {
  .opportunities-nav__form .meta-select {
    max-width: 300px;
    margin-right: 0;
  }
}
@media (max-width: 520px) {
  .opportunities-nav__form .meta-select {
    max-width: 100% !important;
  }
}
.opportunities-nav__form .btn {
  margin-left: clamp(14px, 14px + 10 * (100vw - 768px) / 672, 24px);
  max-width: clamp(150px, 150px + 28 * (100vw - 768px) / 672, 178px);
  width: 100%;
}

@media (max-width: 840px) {
  .opportunities-nav__form .btn {
    margin-left: 0;
  }
}
@media (max-width: 520px) {
  .opportunities-nav__form .btn {
    max-width: 100%;
  }
}
.opportunities-nav__form .btn .btn__icon i {
  color: var(--cl-black);
  font-size: 20px;
  margin-right: clamp(4px, 4px + 4 * (100vw - 768px) / 672, 8px);
}

.opportunities-title {
  margin-bottom: clamp(24px, 24px + 16 * (100vw - 768px) / 672, 40px);
  font-weight: 600;
  font-size: clamp(28px, 28px + 20 * (100vw - 768px) / 672, 48px);
  line-height: 1.12;
  color: #fff;
  max-width: clamp(460px, 460px + 60 * (100vw - 768px) / 672, 520px);
}

.opportunities-title span {
  font-family: "Ysabeau", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opportunities-jobs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(12px, 12px + 12 * (100vw - 1200px) / 240, 24px);
}

@media (max-width: 930px) {
  .opportunities-jobs {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 640px) {
  .opportunities-jobs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.opportunities-job {
  max-width: 392px;
  padding-top: clamp(18px, 18px + 10 * (100vw - 768px) / 672, 28px);
  padding-right: clamp(20px, 20px + 12 * (100vw - 992px) / 448, 32px);
  padding-bottom: clamp(22px, 22px + 21 * (100vw - 768px) / 672, 43px);
  padding-left: clamp(20px, 20px + 16 * (100vw - 992px) / 448, 36px);
  border-radius: 16px;
  /* background: linear-gradient(46deg, rgba(145, 243, 219, 0) 0%, #f4eae500 100%); */
  --gradient: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  transition: 0.3s all;
  position: relative;
  overflow: hidden;
}

.opportunities-job:before {
  transition: 0.3s all;
  content: "";
  opacity: 0;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  z-index: -1;
}

.opportunities-job__title {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: clamp(24px, 24px + 4 * (100vw - 768px) / 672, 28px);
  line-height: 1.32;
  color: var(--cl-white);
  transition: 0.3s all;
}

.opportunities-job__company {
  color: var(--cl-blue);
  font-weight: 600;
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.48;
  margin-bottom: clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  transition: 0.2s all;
}

.opportunities-job__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.opportunities-job__tag {
  padding: clamp(6px, 6px + 3 * (100vw - 768px) / 672, 9px);
  padding-right: clamp(8px, 8px + 4 * (100vw - 768px) / 672, 12px);
  font-weight: 500;
  font-size: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
  line-height: 1.48;
  color: var(--cl-aqua);
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}

.opportunities-job__tag i {
  margin-right: clamp(4px, 4px + 2 * (100vw - 768px) / 672, 6px);
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  color: var(--cl-aqua);
}

.opportunities-job__link {
  font-weight: 800;
  font-size: clamp(16px, 16px + 2 * (100vw - 768px) / 672, 18px);
  line-height: 1.4;
  letter-spacing: 0.03em;
  color: var(--cl-white);
  display: flex;
  align-items: center;
  transition: 0.2s all;
}

.opportunities-job__link:hover {
  opacity: 0.85;
}

.opportunities-job__link:hover i {
  transform: translateX(4px);
}

.opportunities-job__link-wrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.opportunities-job__link .btn__icon {
  display: flex;
}

.opportunities-job__link i {
  transition: 0.2s all;
  margin-left: clamp(14px, 14px + 2 * (100vw - 768px) / 672, 16px);
}

/* background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%); */
.opportunities-job:hover::after {
  opacity: 0;
}

.opportunities-job:hover::before {
  opacity: 1;
}

.opportunities-job:hover .opportunities-job__title {
  color: var(--cl-black-2);
}

.opportunities-job:hover .opportunities-job__company {
  color: rgba(5, 5, 5, 0.7);
}

.opportunities-job:hover .opportunities-job__tag {
  color: rgba(18, 18, 18, 0.8);
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.5) 0%, rgba(238, 236, 235, 0.5) 100%);
  border: 1px solid rgba(5, 5, 5, 0.2);
}

.opportunities-job:hover .opportunities-job__tag i {
  color: rgba(18, 18, 18, 0.6);
}

.opportunities-job:hover .opportunities-job__link {
  color: var(--cl-black-2);
}

.opportunities-bottom {
  width: 100%;
  text-align: center;
  margin-top: clamp(32px, 32px + 23 * (100vw - 768px) / 672, 55px);
}

.opportunities-more {
  font-weight: 800;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: 0.03em;
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.23s all;
}

.opportunities-more:hover {
  transform: translateY(-3px);
  opacity: 0.85;
}

.started {
  position: relative;
  z-index: 5;
}

.started__wrapper {
  padding-top: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(64px, 64px + 56 * (100vw - 768px) / 672, 120px);
}

.started__title.heading-2 {
  font-size: clamp(46px, 46px + 34 * (100vw - 768px) / 672, 80px);
}

.started__title h2 span {
  font-weight: 700;
  font-size: clamp(54px, 54px + 44 * (100vw - 768px) / 672, 98px);
}

.started__block {
  position: relative;
  background: #050505;
  padding: 32px clamp(24px, 24px + 64 * (100vw - 768px) / 672, 88px) clamp(24px, 24px + 26 * (100vw - 768px) / 672, 50px);
  z-index: 6;
  box-shadow: 0px 0px 64px 0px rgba(176, 216, 206, 0.2392156863), 0px 0px 120px 0px rgba(176, 216, 206, 0.0784313725);
  border-radius: 20px;
  overflow: hidden;
  max-width: 951px;
  margin: 0 auto;
  text-align: center;
}

.started__block:before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: -1;
  border-radius: inherit;
  padding: 1px;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

@media (max-width: 768px) {
  .started__block {
    width: 100%;
    padding: 32px 24px 24px;
  }
}
.started__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
}

.started__buttons .started__button {
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .started__buttons {
    flex-direction: column;
  }
  .started__buttons .started__button {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.started__bg {
  position: absolute;
  inset: 0;
}

.started__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.get-to-section {
  background: var(--gradient);
  overflow: hidden;
  z-index: 10;
}

.get-to-section__wrapper {
  padding-top: clamp(48px, 48px + 60 * (100vw - 768px) / 672, 108px);
  padding-bottom: clamp(48px, 48px + 60 * (100vw - 768px) / 672, 108px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 28px;
  color: var(--cl-black);
}

@media (max-width: 860px) {
  .get-to-section__wrapper {
    flex-direction: column;
  }
}
.get-to-section__title {
  font-weight: 600;
  font-size: clamp(38px, 38px + 18 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  letter-spacing: 0.01em;
  color: var(--cl-black);
  /* min-width: ac(390px, 265px); */
  text-wrap: nowrap;
}

.get-to-section__content {
  max-width: clamp(640px, 640px + 64 * (100vw - 768px) / 672, 704px);
  font-weight: 400;
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  line-height: 1.48;
  color: var(--cl-black);
}

@media (max-width: 860px) {
  .get-to-section__content {
    max-width: none;
  }
}
.get-to-section__content p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 8 * (100vw - 768px) / 672, 18px);
}

.get-to-section__button {
  margin-top: clamp(20px, 20px + 16 * (100vw - 768px) / 672, 36px);
  width: 100%;
}

.get-to-section__button .btn {
  border: 1px solid var(--cl-black);
  /* &__text {
      background: var(--cl-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
  } */
}

.get-to-section__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  mix-blend-mode: soft-light;
}

.get-to-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.get-to-section.for-candidates {
  background: transparent;
}

.expertise-section {
  overflow: hidden;
  z-index: 10;
}

.expertise-section__wrapper {
  z-index: 15;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 992px) {
  .expertise-section__wrapper {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 32px;
    padding: clamp(48px, 48px + 16 * (100vw - 992px) / -617, 64px) 0;
  }
}
.expertise-section__content {
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  color: var(--cl-aqua);
  min-width: clamp(390px, 390px + 234 * (100vw - 992px) / 448, 624px);
  margin-top: 20px;
}

@media (max-width: 992px) {
  .expertise-section__content {
    margin-top: 0;
    max-width: 820px;
  }
}
.expertise-section__title {
  color: var(--cl-white);
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
}

.expertise-section__title span {
  font-size: clamp(46px, 46px + 14 * (100vw - 768px) / 672, 60px);
}

.expertise-section__subtitle {
  font-weight: 500;
  font-size: clamp(22px, 22px + 4 * (100vw - 768px) / 672, 26px);
  color: var(--cl-white);
  margin-bottom: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
}

.expertise-section__descr p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 2 * (100vw - 768px) / 672, 12px);
}

.expertise-section__button {
  max-width: 204px;
  margin-top: clamp(28px, 28px + 8 * (100vw - 768px) / 672, 36px);
}

@media (max-width: 1200px) {
  .expertise-section__button {
    width: 100%;
  }
}
.expertise-section__image {
  /* aspect-ratio: 1 / 1; */
  min-width: clamp(550px, 550px + 102 * (100vw - 992px) / 448, 652px);
  transform: translateX(clamp(15px, 15px + 38 * (100vw - 992px) / 448, 53px));
  /* @media (max-width: 1200px) {
      width: 100%;
      height: 100%;
  } */
}

@media (max-width: 992px) {
  .expertise-section__image {
    transform: translateX(0);
    max-width: 820px;
    min-width: auto;
  }
}
.expertise-section__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.choose-section {
  overflow: hidden;
  z-index: 10;
}

.choose-section__wrapper {
  padding-top: clamp(38px, 38px + 70 * (100vw - 768px) / 672, 108px);
  padding-bottom: clamp(40px, 40px + 68 * (100vw - 768px) / 672, 108px);
}

.choose-section__title {
  text-align: center;
}

.choose-section__title span {
  font-size: clamp(48px, 48px + 12 * (100vw - 768px) / 672, 60px);
}

@media (max-width: 992px) {
  .choose-section__slider {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.choose-section__slider .choose-section__buttons {
  margin-top: clamp(32px, 32px + 16 * (100vw - 768px) / 224, 48px);
  display: none;
}

@media (max-width: 992px) {
  .choose-section__slider .choose-section__buttons {
    display: flex;
  }
}
.choose-section .swiper {
  margin: 0;
  overflow: visible;
}

@media (max-width: 992px) {
  .choose-section .swiper {
    max-width: clamp(320px, 320px + 72 * (100vw - 400px) / 592, 392px);
  }
}
@media (max-width: 374px) {
  .choose-section .swiper {
    max-width: clamp(285px, 285px + 35 * (100vw - 320px) / 54, 320px);
  }
}
/* swiper-wrapper */
.choose-section__list {
  margin-top: clamp(48px, 48px + 16 * (100vw - 768px) / 672, 64px);
  display: flex;
  justify-content: center;
  gap: clamp(12px, 12px + 12 * (100vw - 1024px) / 416, 24px);
  flex-wrap: wrap;
}

@media (max-width: 992px) {
  .choose-section__list {
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0;
  }
}
.choose-section__item {
  position: relative;
  padding: clamp(36px, 36px + 14 * (100vw - 768px) / 672, 50px) clamp(20px, 20px + 4 * (100vw - 768px) / 672, 24px);
  border: 1px solid rgba(176, 216, 206, 0.5);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: clamp(281px, 281px + 111 * (100vw - 1024px) / 416, 392px);
  min-height: 202px;
  height: auto;
  transition: 0.3s all;
}

@media (max-width: 992px) {
  .choose-section__item {
    max-width: clamp(320px, 320px + 72 * (100vw - 400px) / 592, 392px);
  }
}
@media (max-width: 374px) {
  .choose-section__item {
    max-width: clamp(285px, 285px + 35 * (100vw - 320px) / 54, 320px);
  }
}
.choose-section__item:hover {
  background: var(--gradient);
}

.choose-section__item:hover .choose-section__content {
  color: var(--cl-black);
}

.choose-section__icon {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #7DCB87;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}

.choose-section__icon:before {
  content: "\e907";
  display: block;
  font-family: "icomoon" !important;
  font-size: 18px;
  color: var(--cl-black);
}

.choose-section__content {
  color: var(--cl-aqua);
  font-weight: 500;
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  transition: 0.3s;
}

.faq-section {
  position: relative;
  z-index: 5;
  background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #eeeceb 76.35%);
  border: 0px 0px 1px 0px;
  overflow: hidden;
}

.faq-section__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: clamp(48px, 48px + 72 * (100vw - 768px) / 672, 120px);
  padding-bottom: clamp(48px, 48px + 60 * (100vw - 768px) / 672, 108px);
  z-index: 6;
}

@media (min-width: 1200px) {
  .faq-section__wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}
.faq-section__title {
  color: var(--cl-black);
}

@media (max-width: 1200px) {
  .faq-section__title {
    width: 100%;
  }
}
.faq-section__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(8px, 16px + -8 * (100vw - 768px) / 672, 16px);
  margin-top: 24px;
}

@media (min-width: 1200px) {
  .faq-section__list {
    margin: 0;
    width: 71.24%;
  }
}
.faq-section__item {
  color: var(--cl-black);
  width: 100%;
  cursor: pointer;
}

.faq-section__item-title {
  font-weight: 500;
  font-size: clamp(22px, 22px + 6 * (100vw - 768px) / 672, 28px);
  line-height: 132%;
  letter-spacing: 1.3px;
}

@media (min-width: 1200px) {
  .faq-section__item-title {
    width: 61.47%;
  }
}
.faq-section__item-button {
  font-size: 28px;
  cursor: pointer;
  transition: 0.3s;
}

.faq-section__item-button i {
  transition: 0.3s;
}

.faq-section__item-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #050505;
  padding: 16px 0;
}

.faq-section__item-top:hover {
  color: var(--gradient);
}

@media (min-width: 1200px) {
  .faq-section__item-top {
    padding: 24px 0;
  }
}
.faq-section__item-bottom {
  margin-top: clamp(16px, 24px + -8 * (100vw - 768px) / 672, 24px);
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: 0.3s;
  width: 100%;
}

.faq-section__item-bottom-content {
  transition: 0.3s;
}

.faq-section__item-bottom-content p, .faq-section__item-bottom-content li {
  font-size: clamp(17px, 17px + 5 * (100vw - 768px) / 672, 22px);
  line-height: 148%;
}

.faq-section__item-bottom-text {
  width: 100%;
}

.faq-section__item.active .faq-section__item-bottom {
  max-height: 1000px;
  opacity: 1;
  transition: 0.3s;
}

.faq-section__item:not(.active) .faq-section__item-bottom {
  max-height: 0 !important;
}

.faq-section__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  mix-blend-mode: soft-light;
}

.faq-section__bg img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.faq-section.clients-page.faq-section {
  background: linear-gradient(125deg, rgba(174, 214, 204, 0.96) 17.35%, #eeeceb 76.35%);
}

.faq-section.clients-page .faq-section__wrapper {
  padding-top: 54px;
}

.supporting-wrap {
  --gradient: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  box-shadow: 0 0 64px 0 rgba(176, 216, 206, 0.24), 0 0 120px 0 rgba(176, 216, 206, 0.08);
  background: var(--cl-black);
  border-radius: 36px;
  overflow: hidden;
  position: relative;
  padding: clamp(22px, 22px + 58 * (100vw - 768px) / 672, 80px) clamp(16px, 16px + 64 * (100vw - 768px) / 672, 80px);
  display: flex;
  justify-content: flex-end;
  margin-bottom: clamp(-309px, -180px + -129 * (100vw - 768px) / 672, -180px);
  /* transform: translateY(ac(-325px, -180px)); */
}

.supporting-image {
  position: absolute;
  inset: 0;
  z-index: -1;
}

.supporting-image:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.31);
  position: absolute;
  inset: 0;
  display: none;
}

@media (max-width: 900px) {
  .supporting-image:after {
    display: block;
  }
}
.supporting-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.supporting-text {
  max-width: clamp(540px, 540px + 60 * (100vw - 768px) / 672, 600px);
}

@media (max-width: 900px) {
  .supporting-text {
    max-width: 100%;
  }
}
.supporting-title {
  margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  font-weight: 600;
  font-size: clamp(32px, 32px + 24 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  color: #fff;
}

.supporting-title span {
  font-family: "Ysabeau", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: clamp(36px, 36px + 24 * (100vw - 768px) / 672, 60px);
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.supporting-descr {
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.48;
  color: #FFF;
}

.supporting-descr p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.ethos {
  padding-top: clamp(280px, 280px + 137 * (100vw - 768px) / 672, 417px);
  padding-bottom: clamp(64px, 64px + 16 * (100vw - 768px) / 672, 80px);
  overflow: hidden;
}

.ethos-nav {
  margin-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
}

.ethos-title {
  font-weight: 600;
  font-size: clamp(34px, 34px + 22 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  letter-spacing: 0.01em;
  color: var(--cl-black);
  margin-bottom: clamp(42px, 42px + 38 * (100vw - 768px) / 672, 80px);
  text-align: center;
}

.ethos-slider {
  overflow: visible;
  max-width: 392px;
  margin-left: 0;
}

@media (max-width: 640px) {
  .ethos-slider {
    max-width: clamp(280px, 280px + 112 * (100vw - 320px) / 320, 392px);
  }
}
.ethos-slider__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  width: 100%;
  gap: clamp(24px, 24px + 8 * (100vw - 768px) / 672, 32px);
}

@media (max-width: 820px) {
  .ethos-slider__bottom {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: clamp(16px, 16px + 8 * (100vw - 375px) / 445, 24px);
  }
}
.ethos .swiper-scrollbar {
  position: relative;
  width: 100%;
  inset: auto;
  background-color: rgba(18, 18, 18, 0.2);
}

.ethos .swiper-scrollbar-drag {
  background-color: var(--cl-black-2);
}

.ethos-card {
  height: clamp(480px, 480px + 45 * (100vw - 768px) / 672, 525px);
  position: relative;
  overflow: hidden;
  border: 1px solid #050505;
  border-radius: 20px;
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  max-width: 392px;
}

@media (max-width: 640px) {
  .ethos-card {
    max-width: clamp(280px, 280px + 112 * (100vw - 320px) / 320, 392px);
  }
}
.ethos-card__arrow {
  width: 114px;
  height: 114px;
  border-radius: 100%;
  background-color: var(--cl-black-2);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -57px;
  cursor: pointer;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.3s all;
}

.ethos-card__arrow:hover .ethos-card__arrow-chevron {
  transform: translateY(-3px);
}

.ethos-card__arrow-chevron {
  position: absolute;
  top: 28px;
  width: 33px;
  height: 17px;
  transition: 0.3s all;
}

.ethos-card__arrow-chevron::before, .ethos-card__arrow-chevron::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #B0D8CE;
  border-radius: 2px;
}

.ethos-card__arrow-chevron::before {
  height: 20px;
  top: 0px;
  left: 72%;
  transform: translateX(-50%);
  rotate: -45deg;
}

.ethos-card__arrow-chevron::after {
  height: 20px;
  top: 0.3px;
  left: 32%;
  transform: translateX(-50%) rotate(45deg);
}

.ethos-card__bg {
  /* opacity: 0.2; */
  background-color: rgba(176, 216, 206, 0.2);
  mix-blend-mode: luminosity;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 1;
  opacity: 1;
}

.ethos-card::before {
  content: "";
  background: #b0d8ce;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 1;
  opacity: 0.2;
}

.ethos-card__overlay {
  background: linear-gradient(0deg, rgba(18, 18, 18, 0.4) 0%, rgb(18, 18, 18) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  z-index: 0;
  opacity: 0.2;
}

.ethos-card__image {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0.2;
}

.ethos-card__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* mix-blend-mode: luminosity; */
}

.ethos-card__nav {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 16px + 8 * (100vw - 768px) / 672, 24px);
  transition: 0.3s all;
}

.ethos-card__num {
  font-style: italic;
  font-weight: 300;
  font-size: clamp(48px, 48px + 8 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  color: var(--cl-black);
  font-family: var(--font-second);
  width: 100%;
  border-bottom: 2px solid var(--cl-black);
  padding-bottom: 4px;
  transition: 0.3s all;
}

.ethos-card__title {
  font-weight: 600;
  font-size: 28px;
  line-height: 1.28;
  letter-spacing: 0.01em;
  color: var(--cl-black);
  transition: 0.3s all;
}

.ethos-card__content {
  height: auto;
  opacity: 1;
  z-index: 32;
  position: relative;
  transform: translateY(200%);
  margin-top: 24px;
  transition: 0.3s all;
  max-height: 346px;
  overflow: auto;
}

@media (max-width: 640px) {
  .ethos-card__content {
    max-height: 260px;
  }
}
.ethos-card__descr {
  font-weight: 400;
  font-size: 19px;
  line-height: 1.48;
  color: #fff;
}

.ethos-card__descr p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.ethos-card.active::before {
  opacity: 1;
  mix-blend-mode: color;
}

.ethos-card.active .ethos-card__arrow {
  opacity: 0;
  bottom: -180px;
}

.ethos-card.active .ethos-card__content {
  transform: translateY(0);
  opacity: 1;
  /* height: auto; */
}

.ethos-card.active .ethos-card__image {
  opacity: 1;
}

.ethos-card.active .ethos-card__overlay {
  opacity: 1;
}

.ethos-card.active .ethos-card__bg {
  opacity: 0;
}

@media (min-width: 580px) {
  .ethos-card.active .ethos-card__nav {
    flex-direction: row;
  }
}
.ethos-card.active .ethos-card__title {
  color: #FFF;
  max-width: 215px;
}

.ethos-card.active .ethos-card__num {
  color: #FFF;
  border-color: #FFF;
}

@media (min-width: 580px) {
  .ethos-card.active .ethos-card__num {
    display: flex;
    justify-content: center;
    height: 100%;
    max-width: 72px;
  }
}
.know-us {
  padding: clamp(72px, 72px + 36 * (100vw - 768px) / 672, 108px) 0;
}

.know-us__wrap {
  display: flex;
  justify-content: space-between;
  gap: clamp(24px, 24px + 68 * (100vw - 768px) / 672, 92px);
}

@media (max-width: 860px) {
  .know-us__wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.know-us__title {
  font-weight: 600;
  font-size: clamp(38px, 38px + 18 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  letter-spacing: 0.01em;
  color: var(--cl-black);
  /* min-width: ac(390px, 265px); */
  text-wrap: nowrap;
}

.know-us__content {
  max-width: clamp(640px, 640px + 64 * (100vw - 768px) / 672, 704px);
  font-weight: 400;
  font-size: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
  line-height: 1.44;
  color: var(--cl-black);
}

@media (max-width: 860px) {
  .know-us__content {
    max-width: none;
  }
}
.know-us__content p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.know-us__btn {
  margin-top: clamp(24px, 24px + 24 * (100vw - 768px) / 672, 48px);
  width: -moz-fit-content;
  width: fit-content;
}

/* testimonials on for candidates page */
.reviews {
  padding-top: clamp(80px, 80px + 60 * (100vw - 768px) / 672, 140px);
  padding-bottom: clamp(86px, 86px + 74 * (100vw - 768px) / 672, 160px);
}

.reviews-wrap {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

@media (max-width: 1240px) {
  .reviews-wrap {
    flex-direction: column;
    justify-content: flex-start;
    gap: clamp(28px, 28px + 10 * (100vw - 768px) / 672, 38px);
  }
}
.reviews-text {
  max-width: clamp(380px, 380px + 14 * (100vw - 768px) / 672, 394px);
}

@media (max-width: 1240px) {
  .reviews-text {
    max-width: 820px;
  }
}
.reviews-title {
  font-weight: 600;
  font-size: clamp(36px, 36px + 20 * (100vw - 768px) / 672, 56px);
  line-height: 1.12;
  color: #fff;
  margin-bottom: clamp(18px, 18px + 6 * (100vw - 768px) / 672, 24px);
}

.reviews-title span {
  font-family: "Ysabeau", sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: clamp(38px, 38px + 22 * (100vw - 768px) / 672, 60px);
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reviews-descr {
  font-weight: 400;
  font-size: clamp(18px, 18px + 2 * (100vw - 768px) / 672, 20px);
  line-height: 1.44;
  color: #b0d8ce;
}

.reviews-descr:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.reviews-swiper-wr {
  display: flex;
  align-items: center;
  gap: clamp(16px, 16px + 4 * (100vw - 768px) / 672, 20px);
}

@media (max-width: 720px) {
  .reviews-swiper-wr {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 46px;
  }
}
.reviews-slider {
  max-width: clamp(545px, 545px + 141 * (100vw - 768px) / 672, 686px);
  max-height: 472px;
  overflow: visible;
  margin: 0;
}

@media (max-width: 1240px) {
  .reviews-slider {
    max-height: clamp(350px, 350px + 84 * (100vw - 500px) / 740, 434px);
  }
}
@media (max-width: 640px) {
  .reviews-slider {
    max-height: 430px;
  }
}
@media (max-width: 440px) {
  .reviews-slider {
    max-height: 480px;
  }
}
.reviews-slider__arrows {
  flex-direction: column;
  margin: 0;
  gap: clamp(38px, 38px + 2 * (100vw - 768px) / 672, 40px);
  width: 56px;
}

@media (max-width: 720px) {
  .reviews-slider__arrows {
    width: auto;
    flex-direction: row;
    gap: 16px;
  }
}
.reviews-slider__arrows .swiper-button-next, .reviews-slider__arrows .swiper-button-prev {
  transform: rotate(90deg);
}

@media (max-width: 720px) {
  .reviews-slider__arrows .swiper-button-next, .reviews-slider__arrows .swiper-button-prev {
    transform: rotate(0);
  }
}
.reviews-card {
  padding: clamp(20px, 20px + 20 * (100vw - 768px) / 672, 40px);
  padding-bottom: clamp(18px, 18px + 14 * (100vw - 768px) / 672, 32px);
  border: 2px solid #e6f8e9;
  border-radius: 20px;
  max-width: clamp(545px, 545px + 141 * (100vw - 768px) / 672, 686px);
  max-height: 472px;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1240px) {
  .reviews-card {
    max-height: clamp(350px, 350px + 84 * (100vw - 500px) / 740, 434px);
  }
}
@media (max-width: 640px) {
  .reviews-card {
    max-height: 430px;
  }
}
@media (max-width: 440px) {
  .reviews-card {
    max-height: 480px;
  }
}
.reviews-card::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  inset: 0;
  transition: 0.3s;
  z-index: -1;
}

.reviews-card.swiper-slide-next {
  border-color: transparent;
}

.reviews-card.swiper-slide-next::before {
  background-color: rgba(106, 106, 106, 0.353);
}

.reviews-card.swiper-slide-next + .swiper-slide {
  border-color: transparent;
}

.reviews-card.swiper-slide-next + .swiper-slide::before {
  background-color: rgba(41, 41, 41, 0.661);
}

.reviews-card__descr {
  font-weight: 400;
  font-size: clamp(18px, 18px + 6 * (100vw - 640px) / 800, 24px);
  line-height: 1.48em;
  color: rgba(5, 5, 5, 0.9);
  margin-bottom: clamp(20px, 20px + 12 * (100vw - 768px) / 672, 32px);
  max-height: 11.84em;
  overflow-y: auto;
}

@media (max-width: 640px) {
  .reviews-card__descr {
    max-height: 17.76em;
  }
}
.reviews-card__descr p:not(:last-child) {
  margin-bottom: clamp(10px, 10px + 6 * (100vw - 768px) / 672, 16px);
}

.reviews-card__user {
  display: flex;
  align-items: center;
  gap: clamp(14px, 14px + 8 * (100vw - 768px) / 672, 22px);
}

@media (max-width: 440px) {
  .reviews-card__user {
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
  }
}
.reviews-card__user-image__wrap {
  --size: clamp(61px, calc(61px + 19.00 * ((100vw - 440px) / 1000)), 80px);
  background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: var(--size);
  height: var(--size);
}

.reviews-card__user-image {
  border-radius: 100%;
  --size: clamp(53px, calc(53px + 19.00 * ((100vw - 440px) / 1000)), 72px);
  width: var(--size);
  height: var(--size);
  border: 1px solid #FFF;
}

.reviews-card__user-name {
  font-weight: 700;
  font-size: clamp(20px, 20px + 8 * (100vw - 768px) / 672, 28px);
  line-height: 1.48;
  color: var(--cl-black);
  margin-bottom: clamp(1px, 1px + 3 * (100vw - 768px) / 672, 4px);
}

.reviews-card__user-position {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.48;
  color: rgba(18, 18, 18, 0.8);
}

/* Common effects */
[data-view] {
  transition-duration: var(--view-speed);
  transition-timing-function: var(--view-easing);
}

[data-view].animated {
  opacity: 1;
  transform: translateZ(0);
}

[data-view=fade], [data-view^=fade], [data-view=zoom], [data-view^=zoom] {
  opacity: 0;
  transition-property: opacity, transform;
}

/* Slide effect */
[data-view=slide], [data-view^=slide] {
  transition-property: transform;
}

[data-view=slide].animated, [data-view^=slide].animated {
  transform: translateZ(0);
}

/* Fade and slide effects */
[data-view=slide-tl], [data-view=fade-tl] {
  transform: translateY(100px) translateX(100px);
}

[data-view=slide-tr], [data-view=fade-tr] {
  transform: translateY(100px) translateX(-100px);
}

[data-view=slide-bl], [data-view=fade-bl] {
  transform: translateY(-100px) translateX(100px);
}

[data-view=slide-br], [data-view=fade-br] {
  transform: translateY(-100px) translateX(-100px);
}

[data-view=fade-l], [data-view=slide-l] {
  transform: translateX(100px);
}

[data-view=fade-r], [data-view=slide-r] {
  transform: translateX(-100px);
}

[data-view=fade-t], [data-view=slide-t] {
  transform: translateY(100px);
}

[data-view=fade-b], [data-view=slide-b] {
  transform: translateY(-100px);
}

/* Zoom effect */
[data-view^=zoom].animated, [data-view^=zoom].animated {
  opacity: 1;
  transform: translateZ(0) scale(1);
}

[data-view=zoom] {
  transform: scale(0.1);
}

[data-view=zoom-l] {
  transform: translateX(100px) scale(0.1);
}

[data-view=zoom-tl] {
  transform: translateY(100px) translateX(100px) scale(0.1);
}

[data-view=zoom-tr] {
  transform: translateY(100px) translateX(-100px) scale(0.1);
}

[data-view=zoom-br] {
  transform: translateY(-100px) translateX(-100px) scale(0.1);
}

[data-view=zoom-bl] {
  transform: translateY(-100px) translateX(100px) scale(0.1);
}

[data-view=zoom-r] {
  transform: translateX(-100px) scale(0.1);
}

[data-view=zoom-t] {
  transform: translateY(100px) scale(0.1);
}

[data-view=zoom-b] {
  transform: translateY(-100px) scale(0.1);
}

/* Rotate effect */
[data-view^=rotate], [data-view^=rotate] {
  opacity: 0;
  transform: rotate(180deg);
}

[data-view=rotate-l] {
  transform: rotate(180deg) translateX(-100px);
}

[data-view=rotate-r] {
  transform: rotate(180deg) translateX(100px);
}

[data-view=rotate-t] {
  transform: rotate(180deg) translateY(-100px);
}

[data-view=rotate-b] {
  transform: rotate(180deg) translateY(100px);
}
/*# sourceMappingURL=styles.17561987.css.map */
